/* eslint-disable no-underscore-dangle */

export const PASSPORT_LOCAL_ENDPOINT = '/passport';
export const PASSPORT_CENTRAL_ENDPOINT =
  window?.__ENDPOINTS__?.CENTRAL_PASSPORT ?? '/login';
export const SELLER_AUTH_ENDPOINT =
  window?.__ENDPOINTS__?.LOGIN_PAGE_ENDPOINT ?? '/login';
export const UPGRADE_COOKIE_ENDPOINT_PATH = '/upgrade-cookie-authv3';
export const DOWNGRADE_COOKIE_ENDPOINT_PATH = '/downgrade-cookie-authv3';
export const GRANT_LOCAL_ENDPOINT = `${PASSPORT_LOCAL_ENDPOINT}/api/v2/auth/grant`;
export const GRANT_CENTRAL_ENDPOINT = `${PASSPORT_CENTRAL_ENDPOINT}/api/v2/auth/grant`;

export const LOGIN_LOCAL_ENDPOINT = `${PASSPORT_LOCAL_ENDPOINT}/api/v2/auth/login`;
export const LOGIN_CENTRAL_ENDPOINT = `${PASSPORT_CENTRAL_ENDPOINT}/api/v2/auth/login`;
export const INTROSPECT_LOCAL_ENDPOINT = `${PASSPORT_LOCAL_ENDPOINT}/api/v2/auth/introspect`;
export const TRANSFORM_SITE_COOKIE_ENDPOINT = `${PASSPORT_LOCAL_ENDPOINT}/api/v2/auth/wild_v3_upgrade`;

export const LOGOUT_LOCAL_ENDPOINT = `${PASSPORT_LOCAL_ENDPOINT}/api/v2/auth/logout`;
export const LOGOUT_CENTRAL_ENDPOINT = `${PASSPORT_CENTRAL_ENDPOINT}/api/v2/auth/logout`;
export const LOGOUT_SELLER_AUTH_ENDPOINT = `${SELLER_AUTH_ENDPOINT}${PASSPORT_LOCAL_ENDPOINT}/api/v2/auth/logout`;

const CMP_ENDPOINT = window?.__ENDPOINTS__?.CMP_ENDPOINT;
export const LOGOUT_CMP_ENDPOINT = CMP_ENDPOINT
  ? `${CMP_ENDPOINT}${PASSPORT_LOCAL_ENDPOINT}/api/v2/auth/logout`
  : '';

const CMP_NEW_ENDPOINT = window?.__ENDPOINTS__?.CMP_NEW_ENDPOINT;
export const LOGOUT_CMP_NEW_ENDPOINT = CMP_NEW_ENDPOINT
  ? `${CMP_NEW_ENDPOINT}${PASSPORT_LOCAL_ENDPOINT}/api/v2/auth/logout`
  : '';

const DELIVERY_OPTIONS_ENDPOINT =
  window?.__ENDPOINTS__?.DELIVERY_OPTIONS_ENDPOINT;
export const LOGOUT_DELIVERY_OPTIONS_ENDPOINT = DELIVERY_OPTIONS_ENDPOINT
  ? `${DELIVERY_OPTIONS_ENDPOINT}${PASSPORT_LOCAL_ENDPOINT}/api/v2/auth/logout`
  : '';

export const UPGRADE_COOKIE_ENDPOINT =
  window?.__ENDPOINTS__?.UPGRADE_COOKIE_ENDPOINT ??
  UPGRADE_COOKIE_ENDPOINT_PATH;
export const DOWNGRADE_COOKIE_ENDPOINT =
  window?.__ENDPOINTS__?.DOWNGRADE_COOKIE_ENDPOINT ??
  DOWNGRADE_COOKIE_ENDPOINT_PATH;

export const AUTH_V3_BASE = window?.__ENDPOINTS__?.AUTH_V3_BASE ?? '/auth';
export const LOGOUT_V3_ENDPOINT = `${AUTH_V3_BASE}/v2/auth/logoff`;
export const LOGIN_V3_ENDPOINT = `${AUTH_V3_BASE}/v2/auth/slide-v3`;
export const SLIDE_CONFIRM_ENDPOINT = `${AUTH_V3_BASE}/v2/auth/slide-v3-confirm`;

export const MFA_BASE = window?.__ENDPOINTS__?.MFA_GET_STATE_ENDPOINT;
export const MFA_STATE = `${MFA_BASE}/api/v1/MFA/request`;
export const MFA_VALIDATE = `${MFA_BASE}/api/v1/MFA/validate`;

export const LOGOUT_NAPI_ENDPOINT =
  window?.__ENDPOINTS__?.LOGOUT_NAPI ?? '/empty_napi';

export const PERSONAL_DATA_LOCAL_ENDPOINT = `${PASSPORT_LOCAL_ENDPOINT}/api/v2/user/personal_data`;
