export const parseList = (list?: any) => {
  try {
    const parsedList = Array.isArray(list) ? list : JSON.parse(list);

    if (!Array.isArray(parsedList)) {
      return [];
    }

    return parsedList.map(String);
    // eslint-disable-next-line no-empty
  } catch {}

  return [];
};
