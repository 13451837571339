import React from 'react';
import { Provider } from 'react-redux';
import { IAdvancedStore } from '@portals/redux-core-modules';
import { ConnectedLayout } from '../../_components/connected-layout';
import { RootComponents } from '../root-components';
import 'reset-css';
import 'normalize.css';
import '@portals/portal-fonts/hauss.css';
import '../../styles/global.css';

type Props = {
  store: IAdvancedStore;
};

export const App = ({ store }: Props) => {
  return (
    <Provider store={store}>
      {/* <DebugRouter /> */}
      <RootComponents />
      <ConnectedLayout />
    </Provider>
  );
};
