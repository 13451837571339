import i18next from 'i18next';
import { APP_NAMESPACE } from '../../_constants/i18next/app-namespace';
import { I18N_DICTIONARY } from './translates/dictionary';
import { errorPageNamespace } from '../../_components/error-page-component/_constants';
import { ERROR_PAGE_TRANSLATIONS_MAP } from '../../_assets/i18next';
export const backendErrorsSubnamespace = 'backend-errors';

export const i18nextNSInit = async (locale: string) => {
  const wasResourceDownloaded = Boolean(
    i18next.getResourceBundle(locale, APP_NAMESPACE)
  );

  if (wasResourceDownloaded) {
    return;
  }

  try {
    for (const entry of Object.entries(I18N_DICTIONARY)) {
      const [currentLocale, file] = entry;
      const translates = await fetch(file).then((r) => r.json());
      i18next.addResourceBundle(currentLocale, APP_NAMESPACE, translates);
    }

    for (const entry of Object.entries(ERROR_PAGE_TRANSLATIONS_MAP)) {
      const [currentLocale, file] = entry;
      const translates = await fetch(file).then((r) => r.json());
      i18next.addResourceBundle(currentLocale, errorPageNamespace, translates);
    }
  } catch (e) {
    console.log('error'); // TODO sentry
  }
};
