import { analyticsEventEmitter } from '@portals/wb-front-analytics-tracker';
import {
  PWA_INIT_EVENT,
  PWA_INSTALL_EVENT,
} from '../../../../_constants/analytics';
import { detectIsPWA } from '../_utils/detect-pwa';

export const initPWAAnalytics = () => {
  window.addEventListener('appinstalled', () => {
    analyticsEventEmitter.sendEvent({
      name: PWA_INSTALL_EVENT,
      customEvent: true,
    });
  });

  const isPWA = detectIsPWA();

  if (isPWA) {
    analyticsEventEmitter.sendEvent({
      name: PWA_INIT_EVENT,
      customEvent: true,
    });
  }
};
