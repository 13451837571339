import Joi from 'joi';

export const responseSchema = Joi.object({
  result: Joi.number().required(),
  error: Joi.any().when('result', {
    not: Joi.valid(0),
    then: Joi.string().required(),
    otherwise: Joi.forbidden(),
  }),
});
