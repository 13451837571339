import React, { memo, RefObject } from 'react';
import classNames from 'classnames/bind';
import style from './index.module.scss';
import { FontSizeType, FontColorType, TagType, WhiteSpaceType } from './_types';

const cn = classNames.bind(style);
const BLOCK_NAME = 'Text';

type PropsType = {
  /** text color */
  color?: FontColorType;
  /** text ellipsis flag */
  isEllipsis?: boolean;
  /** text case flag */
  isUpperCase?: boolean;
  /** text white-space style */
  whiteSpace?: WhiteSpaceType;
  /** text size type */
  size?: FontSizeType;
  /** tag type */
  tagType?: TagType;
  /** text type */
  text: string;
  /** text title attribute */
  title?: string;
  /** text ref object */
  textRef?: RefObject<any>;
};

export const Text = memo(
  ({
    color = 'black',
    isUpperCase,
    size = 'h4',
    tagType: Tag = 'span',
    text,
    isEllipsis,
    whiteSpace,
    title,
    textRef,
  }: PropsType) => (
    <Tag
      ref={textRef}
      className={cn(BLOCK_NAME, {
        [`${BLOCK_NAME}--${size}`]: Boolean(size),
        [`${BLOCK_NAME}--${color}`]: Boolean(color),
        [`${BLOCK_NAME}--white-space-${whiteSpace}`]: Boolean(whiteSpace),
        [`${BLOCK_NAME}--uppercase`]: isUpperCase,
        [`${BLOCK_NAME}--ellipsis`]: isEllipsis,
      })}
      data-name="Text"
      title={title}
    >
      {text}
    </Tag>
  )
);
