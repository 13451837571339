const pendingRequests: { [key: string]: Promise<any> } = {};

// TODO docs
export function asyncSingleton<T>(
  cacheKey: string,
  promiseMaker: () => Promise<T>
) {
  if (!pendingRequests[cacheKey]) {
    pendingRequests[cacheKey] = promiseMaker().finally(() => {
      delete pendingRequests[cacheKey];
    });
  }

  return pendingRequests[cacheKey] as ReturnType<typeof promiseMaker>;
}
