import {
  InterceptorsController,
  MiddlewaresController,
} from '@portals/fetch-api';
import { loginMiddleware } from './login-middleware';
import { loginSeparateDomainMiddleware } from './login-separate-domain-middleware';
import { checkIsCriticalAction } from './_utils/check-is-critical-action';
import { confirmationCriticalAction } from './_utils/confirmation-critical-action';

export const initRequestProxyVerify = () => {
  new InterceptorsController().addResponseInterceptor((response, { retry, requestProtocol }) => {
    if (checkIsCriticalAction(response.status)) {
      return confirmationCriticalAction({
        retryRequest: retry,
        isRpc: requestProtocol === 'jsonRpc',
      }) as Promise<typeof response>;
    }

    return Promise.resolve(response);
  });

  new MiddlewaresController().setMiddleware({
    middleware: loginMiddleware,
    name: '@root/loginMiddleware',
  });
  new MiddlewaresController().setMiddleware({
    middleware: loginSeparateDomainMiddleware,
    name: '@root/loginMiddlewareSeparateDomain',
  });
};
