import { getBrowserInfo } from '../../../../../_utils/get-browser-info';
import { getDeviceUser } from '../../../../../_utils/get-device-user';
import {
  GRANT_CENTRAL_ENDPOINT,
  LOGIN_LOCAL_ENDPOINT,
} from '../../../../../api/endpoints/passport';
import { processOfflineRequestCode } from '../../../../../_utils/process-offline-code';
import { loginRequest } from '../../../login';
import { grantRequest } from '../../../grant';

type ParamsType = {
  middlewaresAreDisabled?: boolean;
};

export const centralGrantAndLocalLogin = async ({
  middlewaresAreDisabled,
}: ParamsType) => {
  const browserInfo = getBrowserInfo();
  const device = getDeviceUser();

  // grant central request
  const {
    data: { token: grantCentralToken, error: grantCentralDataError },
    error: grantCentralError,
    errorText: grantCentralErrorText,
    code: grantCentralResponseCode,
    headers: grantCentralResponseHeaders,
  } = await grantRequest({
    endpoint: GRANT_CENTRAL_ENDPOINT,
    middlewaresAreDisabled,
  });
  if (grantCentralDataError || !grantCentralToken || grantCentralError) {
    processOfflineRequestCode({
      code: grantCentralResponseCode,
      headers: grantCentralResponseHeaders,
    });

    throw Error(grantCentralDataError || grantCentralErrorText);
  }

  // login local request
  const {
    additionalErrors: loginLocalAdditionalErrors,
    code: loginLocalResponseCode,
    headers: loginLocalResponseHeaders,
  } = await loginRequest({
    token: grantCentralToken,
    device,
    version: browserInfo,
    endpoint: LOGIN_LOCAL_ENDPOINT,
    middlewaresAreDisabled,
  });

  if (loginLocalAdditionalErrors && loginLocalAdditionalErrors.error) {
    processOfflineRequestCode({
      code: loginLocalResponseCode,
      headers: loginLocalResponseHeaders,
    });
    throw Error(loginLocalAdditionalErrors.error);
  }

  return true;
};
