/* eslint-disable camelcase */
import { sentryLogger } from '@portals/service-logger';
import { NavigationErrorLogger } from '@portals/service-products';
import { getNavigationErrorMessage } from './get-navigation-error-message';

export const createNavigateToNotFoundLogger = (): NavigationErrorLogger => {
  // eslint-disable-next-line no-console
  console.log('@navigation-loggers init');

  return ({ routeName, data, menuName, navigateUrl }) => {
    const message = getNavigationErrorMessage({
      menuName,
      navigateUrl,
      routeName,
    });

    sentryLogger.sendEvent({
      message,
      level: 'Error',
      tags: {
        'request custom exception': true,
        origin: window.origin,
        routename: routeName,
        url: 'undefined',
      },
      extra: {
        data: JSON.stringify(
          { routeName, data, menuName, navigateUrl },
          null,
          2
        ),
        origin: window.origin,
      },
    });
  };
};
