import {
  SET_PLATFORM_MODULES_DATA,
  SET_LOADING_START,
  SET_LOADING_STOP,
} from './actions';
import { ActionType, PlatformModulesState } from './types';

export const initialState: PlatformModulesState = {
  loading: false,
  modules: [],
};

export const reducerPlatformModules = (
  state: PlatformModulesState = initialState,
  { type, payload }: ActionType
) => {
  switch (type) {
    case SET_PLATFORM_MODULES_DATA:
      return {
        ...state,
        modules: payload.modules,
      };

    case SET_LOADING_START:
      return {
        ...state,
        loading: true,
      };

    case SET_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
