import { IRequestParams } from '@portals/fetch-api';
import { PERSONAL_DATA_LOCAL_ENDPOINT } from '../../../api/endpoints/passport';
import { PersonalDataRequestType } from './types';

export const makeRequestConfig = ({
  middlewaresAreDisabled,
}: PersonalDataRequestType): IRequestParams => {
  return {
    translateFunction: () => '',
    endpoint: PERSONAL_DATA_LOCAL_ENDPOINT,
    credentials: 'include',
    middlewaresAreDisabled,
  };
};
