import { fetchUserPermissionsAction, getUserInfo } from '@portals/service-user';
import { fetchMenuActionSaga } from '@portals/service-products';
import { select, delay, put } from 'redux-saga/effects';
import { Dispatch } from 'redux';
import { getMainMenuRequestConfig } from '../../../../_utils/get-main-menu-request-config';
import { getCookie } from '../../../../_utils/init-app/_utils/get-cookie';
import { getFetchMenuCacheKey } from '../../../../_utils/api-cache/configs/get-fetch-menu-cache-key';
import { coreRequestsHealthCheckIntervalSelector } from '../../selectors';
import {
  incrementHealthCheckIntervalAction,
  resetCoreRequestsErrorAction,
  resetHealthCheckAction,
  resethealthCheckCoreRequestsIntervalAction,
} from '../../actions';

type ParamsType = {
  dispatch: Dispatch;
};

export function* healthCheckWorkerSaga({ dispatch }: ParamsType) {
  while (true) {
    try {
      const { id: userId } = yield select(getUserInfo);

      const refreshInterval = (yield select(
        coreRequestsHealthCheckIntervalSelector
      )) as number;

      yield delay(refreshInterval);

      yield put(
        fetchUserPermissionsAction({
          customPermissionsRequestParams: {
            scope: window.ENVS.MAIN_ABAC_SCOPE,
            // no suppliers availiable
            key: null,
            noRetry: true,
          },
          scope: window.ENVS.MAIN_ABAC_SCOPE,
          withoutErrorNotification: true,
          withoutLoadingIndicator: true,
          successCallbackFinallyLoad: ({ loadingStopAction }) => {
            const fetchMenuCacheKey = getFetchMenuCacheKey({
              getCookie,
            }).key;

            dispatch(loadingStopAction());

            dispatch(
              fetchMenuActionSaga({
                withoutLoadingIndicator: true,
                successActionsArray: [
                  resetCoreRequestsErrorAction,
                  resetHealthCheckAction,
                  resethealthCheckCoreRequestsIntervalAction,
                ],
                errorAction: incrementHealthCheckIntervalAction,
                noRetry: true,
                configs: getMainMenuRequestConfig({
                  cacheKey: fetchMenuCacheKey,
                  userId,
                  menuFromFileRequest: true,
                  defaultShown: true,
                }),
              })
            );
          },
          errorAction: incrementHealthCheckIntervalAction,
        })
      );
    } catch (error) {
      console.error('error in healthCheckWorkerSaga', error);
    }
  }
}
