import { ProjectCachesType } from './_types';

const API_CACHE_STORAGE_PATTERN =
  /([A-Za-z0-9_.,]+)__([A-Za-z0-9_.,]+)__requests-cache/;

type OutputType = Record<string, ProjectCachesType>;

export const getFormattedStorageData = async (
  projectStorageNames: Array<string>
): Promise<OutputType> => {
  const result = await projectStorageNames.reduce(
    async (acc, projectStorageName) => {
      const awaitedAcc: OutputType = await acc;

      if (!API_CACHE_STORAGE_PATTERN.test(projectStorageName)) {
        return awaitedAcc;
      }

      // читаем кэш - берем первый попавшийся timestamp и пишем его в результат
      // по timestamp будет сортировка
      const projectStorage = await caches.open(projectStorageName);

      const projectCachedResponses = await projectStorage.keys();
      const projectCachedFirstResponseUrl = projectCachedResponses?.[0]?.url;

      if (!projectCachedResponses.length || !projectCachedFirstResponseUrl) {
        return awaitedAcc;
      }

      const firstResponse = await projectStorage.match(
        projectCachedFirstResponseUrl
      );

      const firstResponseTimestamp =
        Number(firstResponse?.headers?.get?.('api-timestamp')) ?? 0;

      if (!firstResponseTimestamp) {
        return awaitedAcc;
      }

      // получаем имя проекта из шаблона
      const projectName = projectStorageName.replace(
        API_CACHE_STORAGE_PATTERN,
        '$1'
      );

      const formattedData = {
        storageName: projectStorageName,
        timestamp: firstResponseTimestamp,
      };

      return {
        ...awaitedAcc,
        [projectName]: awaitedAcc[projectName]
          ? [...awaitedAcc[projectName], formattedData]
          : [formattedData],
      };
    },
    {}
  );

  return result;
};
