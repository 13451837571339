import { createSelector } from 'reselect';
import { reducerAppStateName } from './constants';
import { AppState, StatePartAppState } from './_types';
import { initialState } from './reducer';

const productsManagerStorageSelector = (store: StatePartAppState) =>
  store[reducerAppStateName] || initialState;

export const getTranslationIsLoadingSelector = createSelector(
  productsManagerStorageSelector,
  ({ translateIsLoading }: AppState): boolean => translateIsLoading
);

export const initLoadingSelector = createSelector(
  productsManagerStorageSelector,
  ({ initLoading }: AppState): boolean => initLoading
);

export const loginLoadingSelector = createSelector(
  productsManagerStorageSelector,
  ({ loginLoading }: AppState): boolean => loginLoading
);

export const coreRequestsErrorSelector = createSelector(
  productsManagerStorageSelector,
  ({ coreRequestsError }: AppState): boolean => coreRequestsError
);

export const coreRequestsErrorCodeSelector = createSelector(
  productsManagerStorageSelector,
  ({ coreRequestsErrorCode }: AppState): string => coreRequestsErrorCode
);

export const coreRequestsHealthCheckIntervalSelector = createSelector(
  productsManagerStorageSelector,
  ({ healthCheckCoreRequestsInterval }: AppState): number =>
    healthCheckCoreRequestsInterval
);

export const firstRouteTransitionFinishedSelector = createSelector(
  productsManagerStorageSelector,
  ({ firstRouteTransitionFinished }: AppState): boolean =>
    firstRouteTransitionFinished
);

export const logoutIsLoadingSelector = createSelector(
  productsManagerStorageSelector,
  ({ logoutLoading }: AppState): boolean => logoutLoading
);
