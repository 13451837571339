import { PermissionType } from '@portals/service-user';

type ParamsType = {
  features: {
    permitted: any;
    scope: string;
    userID?: number;
  };
};

type OutputType = {
  userPermissions: {
    [key: string]: PermissionType;
  };
};

const DEFAULT_ABAC_VALUES = {
  permitted: {},
  scope: window.ENVS.MAIN_ABAC_SCOPE,
};

export const getFormattedPermissions = ({
  features: { permitted, scope } = DEFAULT_ABAC_VALUES,
}: ParamsType): OutputType => {
  return {
    userPermissions: { [scope]: permitted },
  };
};
