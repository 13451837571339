import React, { memo } from 'react';
import { PerformanceTrackHOC } from '@portals/wb-front-performance-tracker';
import { ModulesListType } from '../../../../_redux/platform-modules';
import { ErrorPageComponent } from '../../../../_components/error-page-component';
import { RouteContent } from './_components/route-content';

type PropsType = {
  platformModules: ModulesListType;
  platformLoaded: boolean;
  translationIsLoading: boolean;
  coreRequestsError: boolean;
  handleLogout: () => void;
  coreRequestsErrorCode: string;
  firstRouteTransitionFinished: boolean;
  appVersion: string;
  isLogoutLoading: boolean;
};

export const WrappedComponent = memo(
  ({
    platformModules,
    platformModules: { Layout, ErrorPage, Preview },
    platformLoaded,
    translationIsLoading,
    handleLogout,
    coreRequestsError,
    coreRequestsErrorCode,
    firstRouteTransitionFinished,
    appVersion,
    isLogoutLoading,
  }: PropsType) => {
    if (!platformLoaded) {
      if (Preview) {
        return <Preview appVersion={appVersion} />;
      }

      return <></>;
    }

    if ((!Layout && platformLoaded) || coreRequestsError) {
      if (ErrorPage) {
        return (
          <ErrorPage
            appVersion={appVersion}
            errorCode={coreRequestsErrorCode}
          />
        );
      }

      return <ErrorPageComponent />;
    }

    return (
      <Layout
        appVersion={appVersion}
        firstRouteTransitionFinished={firstRouteTransitionFinished}
        handleLogout={handleLogout}
        isLogoutLoading={isLogoutLoading}
        platformModules={platformModules}
        previewPage={Preview}
        routeContent={RouteContent}
        translationIsLoading={translationIsLoading}
      />
    );
  }
);

export const LayoutView = PerformanceTrackHOC<PropsType>({
  componentEventName: 'Root',
})(WrappedComponent);
