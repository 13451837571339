import { grantRequest } from '../../../../../../../api/requests/grant';
import { GRANT_CENTRAL_ENDPOINT } from '../../../../../../../api/endpoints/passport';
import { localGrantAndCentralLogin } from '../../../../../../../api/requests/login-with-sso/_utils/local-grant-and-central-login';

export const getCentralGrantToken = async () => {
  // grant central request
  const {
    data: { token: grantCentralToken },
  } = await grantRequest({
    endpoint: GRANT_CENTRAL_ENDPOINT,
    middlewaresAreDisabled: true,
  });

  if (grantCentralToken) {
    return grantCentralToken;
  }

  await localGrantAndCentralLogin({ middlewaresAreDisabled: true });

  const {
    data: {
      token: retryGrantCentralToken,
      error: grantCentralDataError,
      errorText: grantCentralDataErrorText,
    },
  } = await grantRequest({
    endpoint: GRANT_CENTRAL_ENDPOINT,
    middlewaresAreDisabled: true,
  });

  if (grantCentralDataError) {
    throw new Error(grantCentralDataErrorText);
  }

  return retryGrantCentralToken;
};
