import { take, fork } from 'redux-saga/effects';
import { LOGOUT } from '../../actions';
import { logoutWorkerSaga } from './logout-worker-saga';

export const LOGOUT_ROOT_SAGA_NAME = 'LOGOUT_ROOT_SAGA_NAME';

export function* logoutWatcherSaga() {
  while (true) {
    yield take(LOGOUT);
    yield fork(logoutWorkerSaga);
  }
}
