import { PureRestRequest } from '@portals/fetch-api';

export const fetchRuntimeConfigRequest = (endpoint: string) =>
  new PureRestRequest().getRequest({
    parseType: 'text',
    mode: 'cors',
    endpoint,
    cache: 'no-cache',
    isErrorTextStraightToOutput: true,
    retry: 2,
    retryTimeInterval: 3000,
  });
