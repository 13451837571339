import { IResponse, RestRequest } from '@portals/fetch-api';
import { extraVerifyNotAuthRetry } from '../_utils/extra-verify-not-auth-retry';

export type LoginRequestParamsType = {
  options?: {
    notify_code: string;
  };
  token: string;
  device: string;
  version: string;
  endpoint: string;
  middlewaresAreDisabled?: boolean;
};

export const loginRequest = ({
  options,
  token,
  device,
  version,
  endpoint,
  middlewaresAreDisabled,
}: LoginRequestParamsType): Promise<IResponse> =>
  new RestRequest().postRequest({
    endpoint,
    body: {
      options,
      token,
      device,
      version,
    },
    isErrorTextStraightToOutput: true,
    credentials: 'include',
    retry: 2,
    extraVerifyRetry: extraVerifyNotAuthRetry,
    tracingDisabled: true,
    middlewaresAreDisabled,
    extraValidationCallback: ({ statusCode }) => statusCode === 200,
  });
