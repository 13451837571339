import React, { memo } from 'react';

export const RootComponents = memo(() => {
  return (
    <>
      {/* Place your root components here */}
      {/* <DebugRouter /> */}
    </>
  );
});
