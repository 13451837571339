type TNestedObject = Record<string, Record<string, unknown>>;

/**
 * Gets the value at path of object. If the resolved value is undefined, the `null` is returned in its place.
 * Is a null-safe function and will not throw an error if any property on path is a nil value.
 * 
 * @param pathArray - An array of strings representing the path to the desired value within the nested object.
 *                    Each element in the array corresponds to a property name.
 * @param source -The object to search within. This object can have nested structures.
 * @returns The function returns the parent of the value for the specified path within the source object.
            If any key in the pathArray is not found in the object hierarchy, the function returns null.
 */
function getObjectValueByPath<TType extends TNestedObject>(
  source: TNestedObject,
  pathArray: Array<string>
): Record<string, unknown> | null {
  return (
    pathArray
      .slice(0, -1)
      .reduce<any>((acc: TType, prop: string) => acc?.[prop], source) || null
  );
}

/**
 * Removes a property from a nested object dynamically based on a provided path.
 *
 * This function takes a source object, a path as an array of strings representing
 * property names, and attempts to remove the property at the end of the path.
 * It modifies the original object by reference.
 *
 * @param source - The object to modify.
 * @param path - An array of strings representing the path to the property to remove.
 *                Each element in the array corresponds to a property name in the nested object hierarchy.
 * @returns True if the property was removed successfully, false otherwise.
 *          - Returns false if the path is invalid (empty, non-array, or points to a non-existent property)
 *          - Returns false if the property could not be deleted (e.g., due to permission issues)
 */
export function removeObjectValueByPath<TType extends TNestedObject>(
  source: TType,
  path: Array<string>
): boolean {
  if (!path || !Array.isArray(path)) {
    return false;
  }

  const valueToRemove = getObjectValueByPath(source, path);

  if (valueToRemove) {
    const keyToRemove = path[path.length - 1];

    return delete valueToRemove[keyToRemove];
  }

  return false;
}
