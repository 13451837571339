import { IRequestParams } from '@portals/fetch-api';
import { LOGIN_V3_ENDPOINT } from '../../../api/endpoints/passport';
import { SlideRequestType } from './type';
import { responseSchema } from './response-schema';

export const makeRequestConfig = ({
  middlewaresAreDisabled,
}: SlideRequestType): IRequestParams => {
  return {
    endpoint: LOGIN_V3_ENDPOINT,
    responseSchema,
    middlewaresAreDisabled,
    credentials: 'include',
  };
};
