import {
  LOGOUT_CENTRAL_ENDPOINT,
  LOGOUT_CMP_ENDPOINT,
  LOGOUT_CMP_NEW_ENDPOINT,
  LOGOUT_DELIVERY_OPTIONS_ENDPOINT,
  LOGOUT_LOCAL_ENDPOINT,
  LOGOUT_SELLER_AUTH_ENDPOINT,
} from '../api/endpoints/passport';
import { createLogoutEndpoint } from '../api/endpoints/passport/utils/create-logout-endpoint';
import { getSanitizedList } from './auth-v3/downgrade-list/get-sanitized-list';

export const getLogoutEndpoints = (): Array<string> => {
  const list = getSanitizedList(window.LOGOUT_REQUIRED_EXTERNAL_ORIGINS);

  const logoutRequiredExternalEndpoints = list.map((endpoint: string) =>
    createLogoutEndpoint(endpoint)
  );

  const logoutRequiredInternalEndpoints = [
    LOGOUT_LOCAL_ENDPOINT,
    LOGOUT_SELLER_AUTH_ENDPOINT,
    LOGOUT_CMP_ENDPOINT,
    LOGOUT_CMP_NEW_ENDPOINT,
    LOGOUT_DELIVERY_OPTIONS_ENDPOINT, // NOTE SPL-5250
    LOGOUT_CENTRAL_ENDPOINT,
  ].filter((endpoint) => !!endpoint);

  const logoutEndpoint = [
    ...logoutRequiredInternalEndpoints,
    ...logoutRequiredExternalEndpoints,
  ];

  return logoutEndpoint;
};
