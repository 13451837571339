import { Plugin, Router, State } from 'router5';
import { IRouterDependencies } from '@portals/service-router';
import { fetchLoginNavigationActionSaga } from '../../_redux/login';

export const introspectPassportPlugin = (router?: Router): Plugin => {
  // eslint-disable-next-line
  const { store } = router?.getDependencies() as IRouterDependencies;

  return {
    onTransitionSuccess(toState?: State, fromState?: State) {
      const coreRouteFromStateName =
        fromState && fromState.name ? fromState.name.split('.')[0] : null;
      const coreRouteToStateName =
        toState && toState.name ? toState.name.split('.')[0] : null;

      const isTheSameRoute =
        fromState && toState && fromState.name === toState.name;

      // replace используется для сохранение в урл каких-то значений с перезагрузкой роута
      const isRouteReplaced =
        toState?.meta?.options?.replace &&
        coreRouteToStateName === coreRouteFromStateName;

      // если только зашли или уходим
      // если двигаемся внутри одного приложения
      // если не toState.meta.options.replace
      if (
        coreRouteFromStateName &&
        coreRouteToStateName &&
        !isRouteReplaced &&
        !isTheSameRoute
      ) {
        store.dispatch(fetchLoginNavigationActionSaga());
      }
    },
  };
};
