// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as ReactRedux from 'react-redux';
import * as ReduxSaga from 'redux-saga';
import * as reselect from 'reselect';
import * as redux from 'redux';
import * as Joi from 'joi';
import * as ReactRouter from 'react-router5';
import * as router5 from 'router5';
import * as RouterHelpers from 'router5-helpers';
import * as RouterPluginBrowser from 'router5-plugin-browser';
import * as RouterPluginLogger from 'router5-plugin-logger';
import * as serviceRouter from '@portals/service-router';
import * as serviceUser from '@portals/service-user';
import * as i18nextUtils from '@portals/i18next-utils';
import * as pwaModule from '@portals/service-pwa';
import * as i18next from 'i18next';
import * as serviceProducts from '@portals/service-products';
import * as databus from '@portals/databus';
import * as databusReact from '@portals/databus-react';
import * as databusServiceProducts from '@portals/databus-service-products';
import * as databusServiceUser from '@portals/databus-service-user';
import * as reduxCoreModules from '@portals/redux-core-modules';
import * as fetchApiPortals from '@portals/fetch-api';
import * as performanceTracker from '@portals/wb-front-performance-tracker';
import * as analyticsTracker from '@portals/wb-front-analytics-tracker';
import * as classnames from 'classnames';
import * as serviceLogger from '@portals/service-logger';
import * as reactDeviceDetect from 'react-device-detect';
import * as reactVirtualized from 'react-virtualized';

const modules: any = {
  react: React,
  'react-dom': ReactDOM,
  'react-redux': ReactRedux,
  'redux-saga': ReduxSaga,
  redux,
  reselect,
  'react-router5': ReactRouter,
  'router5-helpers': RouterHelpers,
  'router5-plugin-browser': RouterPluginBrowser,
  'router5-plugin-logger': RouterPluginLogger,
  router5,
  'react-device-detect': reactDeviceDetect,
  '@wildberries/service-router': serviceRouter,
  '@portals/service-router': serviceRouter,
  '@wildberries/service-products': serviceProducts,
  '@portals/service-products': serviceProducts,
  '@wildberries/service-user': serviceUser,
  '@portals/service-user': serviceUser,
  '@wildberries/i18next-utils': i18nextUtils,
  '@portals/i18next-utils': i18nextUtils,
  '@wildberries/service-pwa': pwaModule,
  '@portals/service-pwa': pwaModule,
  i18next,
  '@mihanizm56/redux-core-modules': reduxCoreModules,
  '@portals/redux-core-modules': reduxCoreModules,
  '@ravilm/databus': databus,
  '@portals/databus': databus,
  '@ravilm/databus-react': databusReact,
  '@portals/databus-react': databusReact,
  '@hapi/joi': Joi,
  joi: Joi,
  '@wildberries/databus-service-products': databusServiceProducts,
  '@portals/databus-service-products': databusServiceProducts,
  '@wildberries/databus-service-user': databusServiceUser,
  '@portals/databus-service-user': databusServiceUser,
  '@mihanizm56/fetch-api': fetchApiPortals,
  '@portals/fetch-api': fetchApiPortals,
  '@wildberries/wb-front-performance-tracker': performanceTracker,
  '@portals/wb-front-performance-tracker': performanceTracker,
  '@wildberries/wb-front-analytics-tracker': analyticsTracker,
  '@portals/wb-front-analytics-tracker': analyticsTracker,
  '@wildberries/service-logger': serviceLogger,
  '@portals/service-logger': serviceLogger,
  classnames,
  'react-virtualized': reactVirtualized,
};

export const requireModule = (
  externalPackages: Record<string, any>,
  moduleId: string
): any => {
  return externalPackages[moduleId] || modules[moduleId];
};
