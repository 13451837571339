import i18next from 'i18next';
import { mfaStateRequest } from '../../../../../../api/requests/mfa-state-request';
import { MFA_STATE } from '../../../../../../api/endpoints/passport';
import { DefaultCriticalActionErrors } from '../../../../../../_constants';
import { formatSeconds } from '../../../../../../_utils/init-app-utils/requests-proxy/init-request-proxy-verify/_utils/format-seconds';

export const getMfaState = async (deviceId: string) => {
  const {
    data: { sticker },
    error: mfaStateError,
    additionalErrors,
  } = await mfaStateRequest({
    endpoint: MFA_STATE,
    deviceId,
    middlewaresAreDisabled: true,
  });
  if (additionalErrors?.data?.ttl) {
    const { hours, minutes, seconds } = formatSeconds(
      additionalErrors?.data?.ttl
    );

    throw new Error(
      `${i18next.t(
        DefaultCriticalActionErrors.reachedLimit
      )} ${hours} ${minutes} ${seconds}`
    );
  }

  if (mfaStateError) {
    throw new Error(i18next.t(DefaultCriticalActionErrors.requestError));
  }

  return sticker;
};
