import {
  setCriticalActionErrorEvent,
  setIsCriticalActionIncorrectCode,
  setIsUserCriticalAction,
  setUserCriticalActionCode,
} from '@portals/databus-service-user';
import { getMfaState } from '../../../../../../_utils/init-app-utils/requests-proxy/init-request-proxy-verify/_utils/get-mfa-state';
import { criticalAction } from '../../../../../../_utils/init-app-utils/requests-proxy/init-request-proxy-verify/_utils/critical-action';
import { getDeviceIdForCritAction } from './_utils/get-device-id-for-crit-action';
import { ErrorReason } from '@portals/fetch-api';
import i18next from 'i18next';
import { APP_NAMESPACE } from '../../../../../../_constants/i18next/app-namespace';

export const confirmationCriticalAction = async ({
  retryRequest,
  isRpc,
}: {
  retryRequest: () => Promise<Response>;
  isRpc: boolean;
}) => {
  const deviceId = getDeviceIdForCritAction();

  try {
    const { sticker }: { sticker: string } = await getMfaState(deviceId);

    setUserCriticalActionCode(null);
    setIsCriticalActionIncorrectCode(false);
    setIsUserCriticalAction(true);

    const result = await criticalAction({
      retryRequest,
      sticker,
      deviceId,
    });

    return result;
  } catch (error: any) {
    const errorText = String(error.message || error);

    setCriticalActionErrorEvent({ errorText });

    const commonMessage = i18next.t(
      `${APP_NAMESPACE}:backend-errors.failed-to-confirm-action`
    );

    return isRpc
      ? new Response(
          JSON.stringify({
            id: 'json-rpc_???',
            jsonrpc: '2.0',
            error: {
              code: -32007,
              message: commonMessage,
              data: {
                message: commonMessage,
              },
            },
          })
        )
      : new Response(
          JSON.stringify({
            error: true,
            errorText: commonMessage,
            data: null,
            additionalErrors: {
              reasonCode: ErrorReason.CriticalAction,
            },
          }),
          { status: 500, statusText: 'critical action error' }
        );
  } finally {
    setIsUserCriticalAction(false);
  }
};
