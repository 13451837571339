type ParamsType = {
  modulePath: string;
  customModulePath?: string;
};

export const getModulePath = ({
  modulePath,
  customModulePath,
}: ParamsType): string => {
  if (customModulePath) {
    return `${customModulePath}/runtime-config.js`;
  }

  return `${modulePath}runtime-config.js`;
};
