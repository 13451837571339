import { IRequestParams } from '@portals/fetch-api';
import { SLIDE_CONFIRM_ENDPOINT } from '../../../api/endpoints/passport';
import { SlideConfirmRequestType } from './type';
import { responseSchema } from './response-schema';

export const makeRequestConfig = ({
  sticker,
  middlewaresAreDisabled,
}: SlideConfirmRequestType): IRequestParams => {
  return {
    middlewaresAreDisabled,
    endpoint: SLIDE_CONFIRM_ENDPOINT,
    responseSchema,
    body: {
      sticker,
    },
    credentials: 'include',
  };
};
