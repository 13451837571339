import { v4 } from 'uuid';
import { selectedSupplierIdSelector } from '@portals/databus-service-suppliers';

export const LS_KEY = 'deviceIdForCritActionLimited';
const MAX_ID_LENGTH = 64;

/**
 * @returns Возвращает фальшивый device id, не имеет отношения к authv3
 */
export const getDeviceIdForCritAction = () => {
  const existingDeviceId = localStorage.getItem(LS_KEY);
  if (existingDeviceId) {
    return existingDeviceId;
  }

  const uuid = v4();
  const supplierId = selectedSupplierIdSelector();
  const newDeviceId = `crit_${supplierId}_${uuid}`;
  const newDeviceIdLimited = newDeviceId.substring(0, MAX_ID_LENGTH);
  localStorage.setItem(LS_KEY, newDeviceIdLimited);

  return newDeviceIdLimited;
};
