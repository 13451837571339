import { setLocale } from '@portals/i18next-utils';
import { canRedirectoToLanding } from './can-redirect-to-landing';
import { resolveUrl } from '@portals/geo-utils';

/* eslint-disable no-underscore-dangle */
export const redirectToLoginPage = (locale: string) => {
  const currentAbsolutPath = encodeURIComponent(window.location.href);

  const LOGIN_PATH = window.__ENDPOINTS__
    ? window.__ENDPOINTS__.LOGIN_PAGE_ENDPOINT
    : '/login/';

  // устанавливаем локаль приложения пустой строкой, чтобы правильно выставлялась кука при переходе со страницы логина
  setLocale({ locale: '', isFromCookie: true });

  // add / at the end
  const pathLocale = locale ? `${locale}/` : locale;

  if (canRedirectoToLanding() && window.__ENDPOINTS__?.PORTAL_LANDING) {
    window.location.href = resolveUrl(
      `${window.__ENDPOINTS__.PORTAL_LANDING}/${pathLocale}?redirect_url=${currentAbsolutPath}`
    );

    return;
  }

  window.location.href = resolveUrl(
    `${LOGIN_PATH}/${pathLocale}?redirect_url=${currentAbsolutPath}`
  );
};
