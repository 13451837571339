import { loginInstrospectRequest } from '../../../../../../../api/requests/login-introspect';
import { getCentralGrantToken } from '../../../../../../../_utils/init-app-utils/requests-proxy/init-request-proxy-verify/login-separate-domain-middleware/_utils/get-central-grant-token';
import { loginRequest } from '../../../../../../../api/requests/login';
import { getBrowserInfo } from '../../../../../../../_utils/get-browser-info';
import { getDeviceUser } from '../../../../../../../_utils/get-device-user';

export const oldWayExternalLogin = async (apiBaseUrl: string) => {
  const browserInfo = getBrowserInfo();
  const device = getDeviceUser();

  // check local introspect and wait for loginMiddleware if there is need to update SSO
  await loginInstrospectRequest({});

  // grant central request
  const grantCentralToken = await getCentralGrantToken();

  // login local request
  const { error: localLoginError, errorText: localLoginErrorText } =
    await loginRequest({
      token: grantCentralToken,
      device,
      version: browserInfo,
      endpoint: `${apiBaseUrl}/passport/api/v2/auth/login`,
      middlewaresAreDisabled: true,
    });

  if (localLoginError) {
    throw new Error(`Error in domain login ${localLoginErrorText}`);
  }

  return true;
};
