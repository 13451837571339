import { all, call, put } from 'redux-saga/effects';
import { getLocale } from '@portals/i18next-utils';
import { setUserDataForDigitalFingerPrint } from '@portals/fingerprint-utils';
import { logoutRequest } from '../../../../api/requests/logout';
import { redirectToLoginPage } from '../../../../_utils/redirect-to-login';
import { clearUserInfoCache } from '../../../../_utils/api-cache/clear-user-info';
import { logoutNapiRequest } from '../../../../api/requests/logout-napi';
import { logoutViaV3 } from '../../../../_utils/auth-v3/logout-via-v3';
import { RefreshTokenCookieUnavailable } from '../../../../_utils/auth-v3/_common/referesh-token-cookie-unavailable';
import { getLogoutEndpoints } from '../../../../_utils/get-logout-endpoints';
import { cleanupList } from '../../../../_utils/auth-v3/downgrade-list/cleanup-list';
import {
  setLogoutLoadingStartAction,
  setLogoutLoadingStopAction,
} from '../../actions';

const logoutOldWay = () => {
  const logoutEndpoints = getLogoutEndpoints();

  return Promise.allSettled([
    ...logoutEndpoints.map((endpoint: string) => logoutRequest(endpoint)),
    logoutNapiRequest,
  ]);
};

export function* logoutWorkerSaga() {
  try {
    yield put(setLogoutLoadingStartAction());

    try {
      yield all([call(logoutViaV3), call(logoutOldWay)]);
    } catch (authError) {
      if (!(authError instanceof RefreshTokenCookieUnavailable)) {
        throw authError;
      }
    }
    // очистка localStorage списка с посещенными доменами
    cleanupList();
  } catch (error: any) {
    console.error('logoutWorkerSaga get an error', error.message);
  } finally {
    const locale = getLocale({ isFromCookie: true });
    clearUserInfoCache();

    // set nothing to cleanup
    setUserDataForDigitalFingerPrint();

    redirectToLoginPage(locale);
    yield put(setLogoutLoadingStopAction());
  }
}
