import { RequestCacheStrategy } from '@portals/fetch-api';
import { getMenuCacheConfig } from '../../_utils/api-cache/configs/menu-cache-config';

type ParamsType = {
  cacheKey: string;
  strategy?: RequestCacheStrategy;
  userId?: number;
  menuFromFileRequest: boolean;
  defaultShown?: boolean;
};

export const getMainMenuRequestConfig = ({
  cacheKey,
  strategy,
  userId,
  menuFromFileRequest,
  defaultShown,
}: ParamsType) => {
  /* eslint-disable no-underscore-dangle */
  const {
    ENVS: { MAIN_ABAC_SCOPE },
    __ENDPOINTS__: {
      LOAD_MENU_REQUEST_ENDPOINT,
      LOAD_MENU_FILE_REQUEST_ENDPOINT,
    },
  } = window;

  if (
    (LOAD_MENU_REQUEST_ENDPOINT || LOAD_MENU_FILE_REQUEST_ENDPOINT) &&
    MAIN_ABAC_SCOPE
  ) {
    return {
      defaultShown,
      menuFromFileRequest,
      loadMenuRequestEndpoint: menuFromFileRequest
        ? LOAD_MENU_FILE_REQUEST_ENDPOINT
        : LOAD_MENU_REQUEST_ENDPOINT,
      appNamespace: MAIN_ABAC_SCOPE,
      browserCacheParams:
        cacheKey && userId
          ? getMenuCacheConfig({
              cacheKey: menuFromFileRequest
                ? `get-menu-file-request-${cacheKey}-${userId}`
                : `get-menu-request-${cacheKey}-${userId}`,
              strategy: strategy || 'NetworkFirst',
            })
          : null,
    };
  }

  return {
    loadMenuRequestEndpoint: '',
    appNamespace: '',
    menuFromFileRequest: false,
  };
};
