type PropsType = {
  userInfoIsLoading: boolean;
  userPermissionsIsLoading: boolean;
  productsMenuIsLoading: boolean;
  platformModulesIsLoading: boolean;
  platformLoaded: boolean;
  rootInitLoading: boolean;
  loginLoading: boolean;
};

export const getIsLayoutLoaded = ({
  userInfoIsLoading,
  userPermissionsIsLoading,
  productsMenuIsLoading,
  platformModulesIsLoading,
  platformLoaded,
  rootInitLoading,
  loginLoading,
}: PropsType): boolean =>
  (loginLoading ||
    userInfoIsLoading ||
    rootInitLoading ||
    userPermissionsIsLoading ||
    productsMenuIsLoading ||
    platformModulesIsLoading) &&
  !platformLoaded;
