import { SELLER_PORTAL_ACCESS_TOKEN } from '../../../_constants';
import { upgradeCookieRequest } from '../../../api/requests/upgrade-cookie';

export const upgradeExternalDomain = async (apiBaseUrl: string) => {
  const accessToken = localStorage.getItem(SELLER_PORTAL_ACCESS_TOKEN) || '';

  if (!accessToken) {
    throw new Error();
  }

  const { data, errorText } = await upgradeCookieRequest({
    isCurrentDomainEndpoint: false,
    endpoint: apiBaseUrl,
    token: accessToken,
  });

  if (data.success !== 'true') {
    throw new Error(errorText);
  }

  return true;
};
