/* eslint-disable no-new-func */
import { IAdvancedStore } from '@portals/redux-core-modules';
import {
  setFetchPermissionsListener,
  setFetchUserDataListener,
  setRefreshPermissionsListener,
} from '@portals/databus-service-user';
import {
  setFetchProductsListener,
  NavigateToRouteParamsType,
  setNavigateProductListener,
  setPrefetchProductListener,
  PrefetchProductParamsType,
} from '@portals/databus-service-products';
import {
  FetchMenuActionPayloadType,
  fetchMenuActionSaga,
  navigateToProductActionSaga,
  preloadRouteAction,
} from '@portals/service-products';
import {
  fetchUserInfoAction,
  fetchUserPermissionsAction,
  FetchUserPermissionsActionPayloadType,
  getUserInfo,
  getUserPermissions,
  refreshUserFeaturesActionSaga,
  RefreshUserFeaturesType,
  fetchUserPermissionsDataAction,
} from '@portals/service-user';
import { batchActions } from 'redux-batched-actions';
import { getMainMenuRequestConfig } from '../_utils/get-main-menu-request-config';
import { getCookie } from '../_utils/init-app/_utils/get-cookie';
import { getAbacRequestParams } from '../_utils/get-abac-request-params';
import { getUserInfoCacheConfig } from '../_utils/api-cache/configs/user-info-cache-config';
import { getFetchMenuCacheKey } from '../_utils/api-cache/configs/get-fetch-menu-cache-key';
import { getAbacPermissionsDataFeatures } from '../_utils/get-abac-permissions-data-features';
import { getAbacPermissionsCacheConfig } from '../_utils/api-cache/configs/abac-cache-config';
import { ABAC_PERMISSIONS_DATA_CACHE_KEY } from '../_utils/api-cache/_constants';
import { updateAbExperimentsAfterSupplierChange } from './_utils';

const ROOT_ABAC_FEATURES = (window.ENVS.ROOT_ABAC_FEATURES ||
  []) as Array<string>;
const MAIN_ABAC_SCOPE = window.ENVS.MAIN_ABAC_SCOPE || '';

export const initDatabusSubscriptions = (store: IAdvancedStore) => {
  setFetchProductsListener((actionParams: FetchMenuActionPayloadType) => {
    const { id: userId } = getUserInfo(store.getState() as never);
    const permissions = getUserPermissions(
      store.getState() as any,
      MAIN_ABAC_SCOPE
    );

    const fetchMenuCacheKey = getFetchMenuCacheKey({ getCookie }).key;
    const fetchMenuConfig = getMainMenuRequestConfig({
      cacheKey: fetchMenuCacheKey,
      userId,
      menuFromFileRequest:
        !('menuTakenFromFile' in permissions) || permissions.menuTakenFromFile,
      // feature to switch default merging
      // todo rename feature
      defaultShown:
        !('activeSupplier' in permissions) || permissions.activeSupplier,
    });

    const fetchMenuActionSagaParams: FetchMenuActionPayloadType =
      permissions?.menuTakenFromFile
        ? {
            ...actionParams,
            configs: fetchMenuConfig,
            permissions,
          }
        : {
            ...actionParams,
            configs: fetchMenuConfig,
          };

    store.dispatch(fetchMenuActionSaga(fetchMenuActionSagaParams));
  });

  setFetchUserDataListener(() => {
    const { id: userId } = getUserInfo(store.getState() as never);
    const browserCacheParams = userId
      ? getUserInfoCacheConfig({ userId })
      : null;

    store.dispatch(
      fetchUserInfoAction({
        browserCacheParams,
      })
    );
  });

  setFetchPermissionsListener(
    (actionParams: FetchUserPermissionsActionPayloadType) => {
      const { id: userId } = getUserInfo(store.getState() as never);

      const { browserCacheParams, featuresFilterList, key } =
        getAbacRequestParams({
          getCookie,
          userId,
          featuresFilterList: ROOT_ABAC_FEATURES,
        });

      const fetchUserPermissionsParams = {
        ...actionParams,
        scope: window.ENVS.MAIN_ABAC_SCOPE,
        browserCacheParams,
        featuresFilterList,
      };

      const fetchUserPermissionsDataParams = {
        featuresList: getAbacPermissionsDataFeatures(key ? `${key}` : ''),
        browserCacheParams:
          key && userId
            ? getAbacPermissionsCacheConfig({
                cacheKey: `${ABAC_PERMISSIONS_DATA_CACHE_KEY}-${key}-${userId}`,
              })
            : null,
      };

      /**
       * после смены поставщика чистим лишь группы АБ экспериментов из databus-а,
       * чтобы затем получить новые
       */
      updateAbExperimentsAfterSupplierChange(store);

      store.dispatch(
        batchActions([
          fetchUserPermissionsDataAction(fetchUserPermissionsDataParams),
          fetchUserPermissionsAction(
            key
              ? { ...fetchUserPermissionsParams, key }
              : fetchUserPermissionsParams
          ),
        ])
      );
    }
  );

  setRefreshPermissionsListener((actionParams: RefreshUserFeaturesType) => {
    store.dispatch(refreshUserFeaturesActionSaga(actionParams));
  });

  setNavigateProductListener((params: NavigateToRouteParamsType) => {
    store.dispatch(navigateToProductActionSaga(params));
  });

  setPrefetchProductListener((params: PrefetchProductParamsType) => {
    store.dispatch(preloadRouteAction(params));
  });
};
