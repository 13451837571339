import {
  AB_EXPERIMENTS_ABAC_FEATURE,
  AB_EXPERIMENTS_SCOPE,
  PermissionsDataType,
  setDataPermissionsAction,
} from '@portals/databus-service-user';
import { IAdvancedStore } from '@portals/redux-core-modules';
import {
  getAllPermissionsData,
  setUserDataPermissionsAction,
} from '@portals/service-user';
import { removeObjectValueByPath } from './remove-object-value-by-path';

function objectDeepCopy(obj: Record<string, unknown>) {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  const newObj: Record<string, unknown> = {};

  Object.keys(obj).forEach((key) => {
    newObj[key] = objectDeepCopy(obj[key] as never);
  });

  return newObj;
}

export const updateAbExperimentsAfterSupplierChange = (
  store: IAdvancedStore
) => {
  try {
    const currentUserPermissionsData: PermissionsDataType =
      getAllPermissionsData(store.getState() as never);

    const permissionsDataCopy = objectDeepCopy(
      currentUserPermissionsData
    ) as PermissionsDataType;

    removeObjectValueByPath(permissionsDataCopy, [
      AB_EXPERIMENTS_SCOPE,
      AB_EXPERIMENTS_ABAC_FEATURE,
    ]);

    store.dispatch(
      setUserDataPermissionsAction({
        permissionsData: permissionsDataCopy,
      })
    );
    setDataPermissionsAction(permissionsDataCopy);
  } catch (error) {
    console.error('Error in updateAbExperimentsAfterSupplierChange', error);
  }
};
