/* eslint-disable no-new-func */
import { sentryLogger } from '@portals/service-logger';
import {
  BrowserCacheParamsType,
  RequestCacheStrategy,
} from '@portals/fetch-api';
import { getFormattedFilteredFeatures } from '@portals/service-user';
import { appNamespace } from '../../_constants';
import { getAbacPermissionsCacheConfig } from '../../_utils/api-cache/configs/abac-cache-config';

type ParamsType = {
  getCookie: (cookieName: string) => string;
  userId?: number;
  strategy?: RequestCacheStrategy;
  featuresFilterList: Array<string>;
};

type OuputType = {
  scope: string;
  key?: string | number | null;
  attributes?: Array<Record<string, any>>;
  noRetry?: boolean;
  ignoreResponseIdCompare?: boolean;
  browserCacheParams?: BrowserCacheParamsType;
  featuresFilterList: Array<string>;
};

export const getAbacRequestParams = ({
  getCookie,
  strategy,
  userId,
  featuresFilterList,
}: ParamsType): OuputType => {
  const initialSerializedFunction = window.INIT_ABAC_PARAMS_FUNCTION;

  if (initialSerializedFunction) {
    try {
      const parsedFunction = new Function(
        `return ${initialSerializedFunction}`
      )();

      const initialParams = parsedFunction({ getCookie });

      return {
        ...initialParams,
        scope: window.ENVS.MAIN_ABAC_SCOPE,
        ignoreResponseIdCompare: true,
        featuresFilterList: getFormattedFilteredFeatures(featuresFilterList),
        browserCacheParams:
          initialParams.key && userId
            ? getAbacPermissionsCacheConfig({
                cacheKey: `get-abac-features-request-${initialParams.key}-${userId}`,
                strategy,
              })
            : null,
      };
    } catch (error) {
      console.error('error in getInitialAbacParams function', error);

      sentryLogger.sendError({
        error,
        project: appNamespace,
        message: 'error in getInitialAbacParams function',
      });

      return {
        scope: window.ENVS.MAIN_ABAC_SCOPE,
        key: null,
        ignoreResponseIdCompare: true,
        featuresFilterList,
      };
    }
  }

  return {
    scope: window.ENVS.MAIN_ABAC_SCOPE,
    ignoreResponseIdCompare: true,
    featuresFilterList,
  };
};
