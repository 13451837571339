export const getCookie = (cookieName: string) => {
  const cookie: Record<string, string> = {};

  document.cookie.split(';').forEach((cookies) => {
    const [name, value] = cookies.split('=');
    cookie[name.trim()] = value.trim();
  });

  return cookie[cookieName];
};
