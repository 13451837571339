/* eslint-disable no-new-func */

type ParamsType = {
  getCookie: (cookieName: string) => string;
};

type OuputType = {
  key: string;
};

export const getSupplierIdFromCookie = ({
  getCookie,
}: ParamsType): OuputType => {
  const initialSerializedFunction = window.INIT_ABAC_PARAMS_FUNCTION;

  if (initialSerializedFunction) {
    try {
      const parsedFunction = new Function(
        `return ${initialSerializedFunction}`
      )();

      return {
        ...parsedFunction({ getCookie }),
      };
    } catch (error) {
      console.error('error in getSupplierIdfromCookie', error);

      return {
        key: '',
      };
    }
  }

  return {
    key: '',
  };
};
