import { setDataPermissionsAction } from '@portals/databus-service-user';
import {
  PermissionsDataType,
  getAllPermissionsData,
  getFormattedDataABACRefreshResponse,
  getRefreshedPermissions,
  setUserDataPermissionsAction,
} from '@portals/service-user';
import { IAdvancedStore } from '@portals/redux-core-modules';
import { fetchUserPermissionsData } from '../../../_utils/init-app-utils/fetch-permissions-data';
import { getAbacPermissionsDataFeatures } from '../../../_utils/get-abac-permissions-data-features';

type TParams = {
  store: IAdvancedStore;
  supplierId: string | null;
  responseData: Awaited<ReturnType<typeof fetchUserPermissionsData>>;
  withPermissionsMerge?: boolean;
};

const mergePermissionsData = (
  store: IAdvancedStore,
  newPermissions: PermissionsDataType
): PermissionsDataType => {
  const currentUserPermissionsData: PermissionsDataType = getAllPermissionsData(
    store.getState() as never
  );

  const formattedNewPermissionsData = getRefreshedPermissions({
    currentPermissions: currentUserPermissionsData,
    newPermissions,
  });

  return formattedNewPermissionsData;
};

export const formatPermissionsDataAndDispatch = ({
  supplierId,
  store,
  responseData,
  withPermissionsMerge,
}: TParams) => {
  try {
    if (!responseData) {
      return;
    }

    const features = getAbacPermissionsDataFeatures(supplierId || '');

    const formattedPermissionsData = getFormattedDataABACRefreshResponse({
      fullResponse: responseData as any,
      initialValues: features,
    });

    const permissionsData = withPermissionsMerge
      ? mergePermissionsData(store, formattedPermissionsData)
      : formattedPermissionsData;

    store.dispatch(setUserDataPermissionsAction({ permissionsData }));
    setDataPermissionsAction(permissionsData);
  } catch (error) {
    console.error('[formatPermissionsDataAndDispatch] get an error', error);
  }
};
