import { getExternalApiEndpoint } from '../../../../_utils/init-app-utils/requests-proxy/init-request-proxy-verify/_utils/get-external-api-endpoint';
import { parseList } from '../parse-list';
import { getListFromStorage } from '../get-list-from-storage';

export const getSanitizedList = (source?: any) => {
  try {
    const localList = parseList(getListFromStorage());
    const sourceList = parseList(source);
    const list = [...localList, ...sourceList];

    return list.map(getExternalApiEndpoint).reduce<string[]>((acc, item) => {
      if (item && !acc.includes(item)) {
        acc.push(item);
      }

      return acc;
    }, []);
  } catch {
    return [];
  }
};
