import {
  GRANT_LOCAL_ENDPOINT,
  LOGIN_CENTRAL_ENDPOINT,
} from '../../../../../api/endpoints/passport';
import { getBrowserInfo } from '../../../../../_utils/get-browser-info';
import { getDeviceUser } from '../../../../../_utils/get-device-user';
import { grantRequest } from '../../../grant';
import { loginRequest } from '../../../login';

type ParamsType = {
  middlewaresAreDisabled?: boolean;
};

export const localGrantAndCentralLogin = async ({
  middlewaresAreDisabled,
}: ParamsType) => {
  const browserInfo = getBrowserInfo();
  const device = getDeviceUser();

  // grant local request
  const {
    data: { token: grantLocalToken, error: grantLocalDataError },
    error: grantLocalError,
    errorText: grantLocalErrorText,
  } = await grantRequest({
    endpoint: GRANT_LOCAL_ENDPOINT,
    middlewaresAreDisabled,
  });

  if (grantLocalDataError || !grantLocalToken || grantLocalError) {
    console.error('get grantLocalErrorText:', grantLocalErrorText);

    return true;
  }

  // login central request
  const { additionalErrors: loginCentralAdditionalErrors } = await loginRequest(
    {
      token: grantLocalToken,
      device,
      version: browserInfo,
      endpoint: LOGIN_CENTRAL_ENDPOINT,
      middlewaresAreDisabled,
    }
  );

  if (loginCentralAdditionalErrors && loginCentralAdditionalErrors.error) {
    console.error(
      'get loginCentralAdditionalErrors:',
      loginCentralAdditionalErrors.error
    );

    return true;
  }

  return true;
};
