// https://web.dev/customize-install/#detect-launch-type

export const detectIsPWA = () => {
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

  if (document.referrer.startsWith('android-app://')) {
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (navigator.standalone || isStandalone) {
    return true;
  }

  return false;
};
