import {
  BrowserCacheParamsType,
  IResponse,
  RequestCacheStrategy,
} from '@portals/fetch-api';
import { DEFAULT_API_CACHE_PARAMS } from '../../../_constants';
import { USER_INFO_CACHE_KEY } from '../_constants';

type ParamsType = {
  onUpdateCache?: (response: IResponse) => void;
  strategy?: RequestCacheStrategy;
  userId: number;
};

export const getUserInfoCacheConfig = ({
  onUpdateCache,
  strategy,
  userId,
}: ParamsType): BrowserCacheParamsType => {
  return {
    ...DEFAULT_API_CACHE_PARAMS,
    strategy: strategy || 'NetworkFirst',
    requestCacheKey: `${userId}-${USER_INFO_CACHE_KEY}`,
    onUpdateCache,
  };
};
