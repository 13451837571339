import { IRequestParams } from '@portals/fetch-api';
import { LOGOUT_V3_ENDPOINT } from '../../../api/endpoints/passport';
import { responseSchema } from './response-schema';

export const makeRequestConfig = (): IRequestParams => {
  return {
    endpoint: LOGOUT_V3_ENDPOINT,
    responseSchema,
    credentials: 'include',
  };
};
