/* eslint-disable no-console */
import { getFormattedStorageData } from './get-formatted-storage-data';
import { pruneProjectStorages } from './prune-projects-versions';
import { ProjectCachesType } from './_types';

export const pruneProjectsApiCaches = async () => {
  try {
    const t1 = performance.now();
    const prunedCaches: ProjectCachesType = [];

    console.log('%cpruneProjectsApiCaches run', 'color: blue;padding:3px;');

    const projectsStorageNames = await caches.keys();

    const formattedStorageData = await getFormattedStorageData(
      projectsStorageNames
    );

    Promise.allSettled(
      Object.values(formattedStorageData).map(async (projectStorages) => {
        try {
          if (projectStorages.length > 2) {
            await pruneProjectStorages({ projectStorages, prunedCaches });
          }
        } catch (error) {
          console.error(error);
        }
      })
    );

    const t2 = performance.now();

    // eslint-disable-next-line no-console
    console.log('%cpruneProjectsApiCaches result', 'color: blue;padding:3px;', {
      duration: t2 - t1,
      prunedCaches,
      prunedCachesCount: prunedCaches.length,
    });
  } catch (error) {
    console.error('Error in pruneProjectsApiCaches', error);
  }
};
