import './public-path';
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-underscore-dangle */

import React from 'react';
import { createAppStore } from '@portals/redux-core-modules';
import { configureRouter, actionHandler } from '@portals/service-router';
import { createRoot } from 'react-dom/client';
import i18next from 'i18next';
import {
  getI18nextRequestEndpoint,
  i18nReducer,
  I18NEXT_REDUCER_NAME,
  fetchLanguagesWatcherSaga,
  FETCH_LANGUAGES_WATCHER_SAGA_NAME,
} from '@portals/i18next-utils';
import {
  reducerProductsName,
  createFetchProductsWatcherSaga,
  fetchProductsSagaName,
  reducerProducts,
  createNavigateToProductWatcherSaga,
  NAVIGATE_TO_PRODUCT_SAGA_NAME,
  fetchRoutePlugin,
  MenuMainProductConfigListType,
  createPreFetchRouteWatcherSaga,
  PREFETCH_ROUTE_SAGA_NAME,
} from '@portals/service-products';
import { RouterProvider } from 'react-router5';
import {
  fetchUserInfoWatcherSagaName,
  fetchUserPermissionsWatcherSagaName,
  reducerUser,
  reducerUserName,
  fetchUserInfoWatcherSaga,
  fetchUserPermissionsWatcherSaga,
} from '@portals/service-user';
import { sentryLogger } from '@portals/service-logger'; /**/
import { loadModule } from '@portals/service-modules-load';
import { ABORT_REQUEST_EVENT_NAME } from '@portals/fetch-api';
import {
  fetchUserPermissionsDataWatcherSaga,
  fetchUserPermissionsDataWatcherSagaName,
  refreshUserFeaturesWatcherSaga,
  refreshUserFeaturesWatcherSagaName,
} from '@portals/service-user/lib/redux-module';
import { analyticsEventEmitter } from '@portals/wb-front-analytics-tracker';
import { reducerPWA, PWAReducerName } from '@portals/service-pwa';
import { initDigitalFingerPrint } from '@portals/fingerprint-utils';
import { App } from './_components/app';
import { reducerPlatformModulesName } from './_redux/platform-modules';
import { reducerPlatformModules } from './_redux/platform-modules/reducer';
import {
  fetchPlatformModulesWatcherSaga,
  FETCH_PLATFORM_MODULES_WATCHER_SAGA_NAME,
} from './_redux/platform-modules/sagas/fetch-platform-modules/fetch-platform-modules-watcher-saga';
import {
  reducerAppStateName,
  reducerAppState,
  setTranslateLoadingStartAction,
  setTranslateLoadingStopAction,
  LOGOUT_ROOT_SAGA_NAME,
  logoutWatcherSaga,
  healthCheckWatcherSaga,
  HEALTH_CHECK_SAGA_NAME,
} from './_redux/app-state-module';
import { initApp } from './_utils/init-app';
import {
  registerServiceWorker,
  unregisterServiceWorker,
} from './serviceWorker';
import { NOT_FOUND_ROUTE_NAME } from './_constants/routes';
import {
  loginNavigationWatcherSaga,
  LOGIN_NAVIGATION_SAGA_NAME,
} from './_redux/login';
import { introspectPassportPlugin } from './router/plugins/login';
import { i18nextRequest } from './api/requests/i18next';
import { createNavigateToNotFoundLogger } from './_utils/navigate-loggers';
import { setWindowEndpointsMiddleware } from './router/middlewares/set-window-endpoints';
import { initPWAAnalytics } from './_utils/init-app-utils/pwa/analytics';
import { initAnalytics } from './_utils/init-app-utils/analytics-init';
import { setPwaPrompt } from './_utils/init-app-utils/pwa/prompt';
import { initPerformanceMetrics } from './_utils/init-app-utils/init-performance-metrics';
import { initRequestProxies } from './_utils/init-app-utils/requests-proxy';
import { transitionPlugin } from './router/plugins/transition';
import { SWClientUpdatePlugin } from './router/plugins/sw-client-update';

initDigitalFingerPrint({
  environment: window.ENVS.RUNTIME_ENV || 'prod',
  enabledVendors: {
    facct: !!window.ENVS.FINGERPRINT_FACCT_ENABLED
  },
});

const {
  ENVS: { SENTRY_DSN_CLIENT, DISABLED_SW = false },
  ADDITIONAL_MENU_CONFIG,
} = window;

// конфигурирование sentry
if (SENTRY_DSN_CLIENT) {
  sentryLogger.init({
    dsn: SENTRY_DSN_CLIENT,
    release: `root-monorepo@${process.env.NX_PUBLIC_APP_VERSION}`,
  });
  console.log('@sentry init done');
}

if (DISABLED_SW) {
  unregisterServiceWorker();
} else {
  registerServiceWorker();
}

// init request proxies
initRequestProxies();

// router init
const router = configureRouter({
  defaultRoute: NOT_FOUND_ROUTE_NAME,
  eventNameToCancelRequests: ABORT_REQUEST_EVENT_NAME,
  enablei18nMiddleware: true,
  modifiedNavigation: true,
  disabledActionHandler: true,
  enableDeactivationMiddleware: true,
});

// store init
const store = createAppStore({
  router,
  getDefaultMiddlewareOptions: {
    serializableCheck: false,
  },
  rootReducers: {
    [reducerProductsName]: reducerProducts,
    [reducerUserName]: reducerUser,
    [reducerPlatformModulesName]: reducerPlatformModules,
    [reducerAppStateName]: reducerAppState,
    [I18NEXT_REDUCER_NAME]: i18nReducer,
    [PWAReducerName]: reducerPWA,
  },
  rootSagas: {
    [fetchProductsSagaName]: createFetchProductsWatcherSaga({
      loadModuleFunction: loadModule,
      additionalProductsData:
        ADDITIONAL_MENU_CONFIG as MenuMainProductConfigListType, // can not make typings on window envs
    }),
    [fetchUserInfoWatcherSagaName]: fetchUserInfoWatcherSaga,
    [fetchUserPermissionsWatcherSagaName]: fetchUserPermissionsWatcherSaga,
    [fetchUserPermissionsDataWatcherSagaName]:
      fetchUserPermissionsDataWatcherSaga,
    [FETCH_PLATFORM_MODULES_WATCHER_SAGA_NAME]: fetchPlatformModulesWatcherSaga,
    [LOGOUT_ROOT_SAGA_NAME]: logoutWatcherSaga,
    [PREFETCH_ROUTE_SAGA_NAME]: createPreFetchRouteWatcherSaga(loadModule),
    [NAVIGATE_TO_PRODUCT_SAGA_NAME]: createNavigateToProductWatcherSaga({
      loadModuleFunction: loadModule,
      notFoundRouteName: 'page-not-found',
      navigationErrorLogger: createNavigateToNotFoundLogger(),
    }),
    [refreshUserFeaturesWatcherSagaName]: refreshUserFeaturesWatcherSaga,
    [FETCH_LANGUAGES_WATCHER_SAGA_NAME]: fetchLanguagesWatcherSaga,
    [LOGIN_NAVIGATION_SAGA_NAME]: loginNavigationWatcherSaga,
    [HEALTH_CHECK_SAGA_NAME]: healthCheckWatcherSaga,
  },
  eventNameToCancelRequests: ABORT_REQUEST_EVENT_NAME,
  reduxStoreName: '@suppliers-portal-store',
});

router.useMiddleware(setWindowEndpointsMiddleware(store));

// router settings
router.setDependencies({
  store,
  i18nextConfig: {
    getLocale: () => i18next.language,
    i18next,
    i18nextRequest,
    actionToStartLoading: setTranslateLoadingStartAction,
    actionToStopLoading: setTranslateLoadingStopAction,
    createEndpoint: getI18nextRequestEndpoint,
    formatterResponseData: (data: { translate: Record<string, any> }) =>
      data.translate,
  },
  analyticsEventEmitter,
});

// @ts-ignore
router.usePlugin(fetchRoutePlugin);
// @ts-ignore
router.usePlugin(introspectPassportPlugin);
// @ts-ignore
router.usePlugin(transitionPlugin);

// @ts-ignore
router.usePlugin(SWClientUpdatePlugin);

router.useMiddleware(actionHandler);

initAnalytics();
initPerformanceMetrics();
initPWAAnalytics();
setPwaPrompt(store);

const container = document.getElementById('root') as HTMLElement;

const root = createRoot(container);

// start App
initApp({ store, router }).then(() =>
  root.render(
    <RouterProvider router={router}>
      <App store={store} />
    </RouterProvider>
  )
);
