import { IResponse, PureRestRequest } from '@portals/fetch-api';
import { LOGOUT_NAPI_ENDPOINT } from '../../../api/endpoints/passport';
import { extraVerifyNotAuthRetry } from '../_utils/extra-verify-not-auth-retry';

export const logoutNapiRequest = (): Promise<IResponse> =>
  new PureRestRequest().getRequest({
    credentials: 'include',
    endpoint: LOGOUT_NAPI_ENDPOINT,
    isErrorTextStraightToOutput: true,
    retry: 2,
    extraVerifyRetry: extraVerifyNotAuthRetry,
    tracingDisabled: true,
    middlewaresAreDisabled: true,
  });
