import { IResponse, PureRestRequest } from '@portals/fetch-api';

const PUBLIC_URL = process.env.PUBLIC_URL;

export const manifestRequest = (): Promise<IResponse> =>
  new PureRestRequest().getRequest({
    endpoint: `${PUBLIC_URL}/manifest.json`,
    isErrorTextStraightToOutput: true,
    tracingDisabled: true,
    pureJsonFileResponse: true,
  });
