import {
  BrowserCacheParamsType,
  RequestCacheStrategy,
} from '@portals/fetch-api';
import { DEFAULT_API_CACHE_PARAMS } from '../../../_constants';

type ParamsType = {
  cacheKey: string;
  strategy?: RequestCacheStrategy;
};

export const getAbacPermissionsCacheConfig = ({
  cacheKey,
  strategy,
}: ParamsType): BrowserCacheParamsType => {
  return {
    ...DEFAULT_API_CACHE_PARAMS,
    strategy: strategy || 'NetworkFirst',
    requestCacheKey: cacheKey,
  };
};
