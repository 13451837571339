import { IResponse, PureRestRequest } from '@portals/fetch-api';
import { extraVerifyNotAuthRetry } from '../_utils/extra-verify-not-auth-retry';

export const logoutRequest = (endpoint: string): Promise<IResponse> =>
  new PureRestRequest().postRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    body: {},
    credentials: 'include',
    endpoint,
    isErrorTextStraightToOutput: true,
    retry: 2,
    extraVerifyRetry: extraVerifyNotAuthRetry,
    tracingDisabled: true,
    extraValidationCallback: ({ statusCode }) => statusCode === 200,
    middlewaresAreDisabled: true,
  });
