import { IResponse, OFFLINE_STATUS_CODE } from '@portals/fetch-api';
import { REQUEST_ERROR_STATUS_CODE } from '@portals/fetch-api/dist/constants';
import { personalDataRequest } from '../../../api/requests/personal-data';
import { upgradeCookieRequest } from '../../../api/requests/upgrade-cookie';
import { asyncSingleton } from '../../../_utils/auth-v3/async-singleton';

export class ValidateTokenError extends Error {
  constructor(public message: string, public code?: number) {
    super(message);
  }
}

const handleOfflineError = ({ code, headers, errorText, error }: IResponse) => {
  if (!error) {
    return;
  }

  if (
    code === OFFLINE_STATUS_CODE ||
    // проверка для Safari, может вернуть пустые хидеры в оффлайне
    (code === REQUEST_ERROR_STATUS_CODE && !Object.keys(headers).length)
  ) {
    throw new ValidateTokenError(errorText, OFFLINE_STATUS_CODE);
  }

  throw new ValidateTokenError(errorText, code);
};

const validateTokenRaw = async (accessToken: string) => {
  const upgradeCookieResponse = await upgradeCookieRequest({
    token: accessToken,
    middlewaresAreDisabled: true,
  });

  handleOfflineError(upgradeCookieResponse);

  const personalDataResponse = await personalDataRequest({
    middlewaresAreDisabled: true,
  });

  handleOfflineError(personalDataResponse);

  return true;
};

export const validateToken = (accessToken: string) =>
  asyncSingleton('validationToken', () => validateTokenRaw(accessToken));
