import { IMiddleware, IResponse } from '@portals/fetch-api/dist/types';
import { OFFLINE_STATUS_CODE } from '@portals/fetch-api';
import { saveExternalApiPath } from '../../../../../_utils/auth-v3/downgrade-list';
import { getExternalApiEndpoint } from '../_utils/get-external-api-endpoint';
import { checkIsExternalApiRequest } from '../_utils/check-is-external-api-request';
import { getIsNotLoggedInSeparateDomain } from './_utils/get-is-not-logged-in-separate-domain';
import { loginExternalApi } from './_utils/login-external-api';

export const loginSeparateDomainMiddleware: IMiddleware = async ({
  pureRequestParams,
  retryRequest,
  response,
}): Promise<IResponse> => {
  try {
    const isExternalApiRequest = checkIsExternalApiRequest(
      pureRequestParams.endpoint
    );

    // works only with current domain
    if (!isExternalApiRequest) {
      return response;
    }

    const requestEndpoint = getExternalApiEndpoint(pureRequestParams.endpoint);

    saveExternalApiPath(requestEndpoint);

    if (!response.error || response.code === OFFLINE_STATUS_CODE) {
      return response;
    }

    // check if not logined
    const isNotLogined = getIsNotLoggedInSeparateDomain({
      responseBodyJson: JSON.stringify(pureRequestParams.responseBody),
      code: pureRequestParams.code,
    });

    if (!isNotLogined) {
      return response;
    }

    await loginExternalApi(requestEndpoint);

    // retry request with login
    const responseWithLogin = await retryRequest({
      middlewaresAreDisabled: true,
    });

    // return response
    return responseWithLogin;
  } catch (error) {
    // if SSo not valid - there will be an exception - silent exit
    console.error('loginSeparateDomainMiddleware error', error);

    return response;
  }
};
