import { MFA_VALIDATE } from '../../../../../../api/endpoints/passport';
import { mfaValidateRequest } from '../../../../../../api/requests/mfa-validate';

export const mfaValidate = async ({
  code,
  sticker,
  deviceID,
}: {
  code: number;
  sticker: string;
  deviceID: string;
}) => {
  const params = {
    code,
    sticker,
    deviceID,
  };

  const { error: mfaValidateError, errorText: mfaValidateErrorText } =
    await mfaValidateRequest({
      endpoint: MFA_VALIDATE,
      middlewaresAreDisabled: true,
      params,
    });

  if (mfaValidateError) {
    throw new Error(`Error in mfa validate ${mfaValidateErrorText}`);
  }

  return true;
};
