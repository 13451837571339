type TOutput = {
  hours: string;
  minutes: string;
  seconds: string;
};

const SEC_IN_HOUR = 3600;
const TIME_SCALE = 60;

export const formatSeconds = (seconds: number): TOutput => {
  if (!seconds) {
    return {
      hours: '0',
      minutes: '0',
      seconds: '0',
    };
  }

  const hours = Math.floor(seconds / SEC_IN_HOUR);
  const modHourSeconds = Math.floor(seconds % SEC_IN_HOUR);
  const minutes = Math.floor(modHourSeconds / TIME_SCALE);
  const modMinuteSeconds = Math.floor(modHourSeconds % TIME_SCALE);

  return {
    hours: `${hours} ч`,
    minutes: `${minutes} мин`,
    seconds: `${modMinuteSeconds} сек`,
  };
};
