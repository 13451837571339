import React, { memo } from 'react';
import classnames from 'classnames/bind';
import i18next from 'i18next';
import { Text } from '../../../../_components/text';
import { errorPageNamespace } from '../../_constants';
import styles from './index.module.scss';

const cn = classnames.bind(styles);

const BLOCK_NAME = 'Card';

export const Card = memo(() => (
  <div className={cn(`${BLOCK_NAME}`)}>
    <div className={cn(`${BLOCK_NAME}__text-wrapper`)}>
      <div className={cn(`${BLOCK_NAME}__text`)}>
        <Text
          color="black"
          size="h0"
          text={i18next.t(`${errorPageNamespace}:title`)}
        />
      </div>
      <div className={cn(`${BLOCK_NAME}__text`)}>
        <Text
          color="black"
          size="h3"
          text={i18next.t(`${errorPageNamespace}:text`)}
        />
      </div>
    </div>
  </div>
));
