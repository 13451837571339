import {
  AB_EXPERIMENTS_ABAC_FEATURE,
  AB_EXPERIMENTS_SCOPE,
} from '@portals/databus-service-user';

const ABAC_PERMISSIONS_DATA_FEATURES = [
  { scope: AB_EXPERIMENTS_SCOPE, featureKey: AB_EXPERIMENTS_ABAC_FEATURE },
];

export const getAbacPermissionsDataFeatures = (supplierId: string) =>
  ABAC_PERMISSIONS_DATA_FEATURES.map((feature) => ({
    ...feature,
    key: supplierId,
  }));
