import React, { useEffect } from 'react';
import { RouteNode } from '@portals/service-router';
import { Router, State } from 'router5';
import { PerformanceTrackHOC } from '@portals/wb-front-performance-tracker';
import { EXTERNAL_FRAMEWORKS } from '../../../../../../_constants/frameworks';
import { appLoadedMeasureSendCallback } from '../../../../../../_utils/init-app-utils/init-performance-metrics';

type PropsType = {
  content: any;
  framework?: keyof typeof EXTERNAL_FRAMEWORKS;
  router: Router;
  route: State;
};

const Content = ({ content, framework, router, route }: PropsType) => {
  useEffect(() => {
    if (framework && EXTERNAL_FRAMEWORKS[framework]) {
      content({ router });
    }
  }, [content, framework, router]);

  if (framework === EXTERNAL_FRAMEWORKS.vue) {
    return (
      <div key={route.name} id="vue-root-container">
        <div id="vue-root" />
      </div>
    );
  }

  if (framework === EXTERNAL_FRAMEWORKS.angular) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <angular-root />;
  }

  if (framework === EXTERNAL_FRAMEWORKS.react) {
    return <div id="react-root" />;
  }

  return content;
};

const MeasuredContent = PerformanceTrackHOC<PropsType>({
  componentEventName: 'AppLoaded',
  mountedCallback: appLoadedMeasureSendCallback,
})(Content);

export const RouteContent = () => (
  <RouteNode nodeName="">
    {({ content, framework, router, route }) => (
      <MeasuredContent
        content={content}
        framework={framework}
        route={route}
        router={router}
      />
    )}
  </RouteNode>
);
