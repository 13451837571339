import {
  removeUserDataCriticalActionCodeListener,
  setIsCriticalActionIncorrectCode,
  setUserDataCriticalActionCodeListener,
  setUserSendCodeLoadingAction,
  userDataIsCriticalActionSelector,
} from '@portals/databus-service-user';
import i18next from 'i18next';
import { mfaValidate } from '../../../../../../_utils/init-app-utils/requests-proxy/init-request-proxy-verify/_utils/mfa-validate';
import { DefaultCriticalActionErrors } from '../../../../../../_constants';

export const criticalAction = async ({
  retryRequest,
  sticker,
  deviceId,
}: {
  retryRequest: () => Promise<Response>;
  sticker: string;
  deviceId: string;
}) => {
  return new Promise((resolve, reject) => {
    setUserDataCriticalActionCodeListener(({ criticalActionCode }) => {
      if (criticalActionCode) {
        mfaValidate({ code: criticalActionCode, sticker, deviceID: deviceId })
          .then(() => {
            return retryRequest()
              .then((response) => {
                resolve(response);
              })
              .finally(() => {
                setIsCriticalActionIncorrectCode(false);
              });
          })
          .catch(() => {
            setIsCriticalActionIncorrectCode(true);
          })
          .finally(() => {
            setUserSendCodeLoadingAction(false);
          });
      }
      const userDataIsCriticalAction = userDataIsCriticalActionSelector();

      if (!userDataIsCriticalAction) {
        reject(i18next.t(DefaultCriticalActionErrors.abortEnterCodeError));
      }
    });
  }).finally(() => {
    removeUserDataCriticalActionCodeListener();
  });
};
