import { OFFLINE_STATUS_CODE } from '@portals/fetch-api';
import { SELLER_PORTAL_ACCESS_TOKEN } from '../../../_constants';
import { validateToken } from '../../../_utils/auth-v3/validate-token';
import { asyncSingleton } from '../../../_utils/auth-v3/async-singleton';

const verifyExistingTokenRaw = async () => {
  const accessToken = localStorage.getItem(SELLER_PORTAL_ACCESS_TOKEN) || '';

  if (!accessToken) {
    throw new Error();
  }

  try {
    await validateToken(accessToken);
  } catch (error: any) {
    if (error?.code === OFFLINE_STATUS_CODE) {
      // NOTE
      // Если оффлайн, считаем что токен валидный, так как он существовал
      return true;
    }

    localStorage.removeItem(SELLER_PORTAL_ACCESS_TOKEN);
    throw error;
  }

  return true;
};

export const verifyExistingToken = () =>
  asyncSingleton('verifyExistingToken', verifyExistingTokenRaw);
