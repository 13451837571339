import { IRequestParams } from '@portals/fetch-api';
import {
  UPGRADE_COOKIE_ENDPOINT,
  UPGRADE_COOKIE_ENDPOINT_PATH,
} from '../../../api/endpoints/passport';
import { UpgradeCookieRequestType } from './types';

export const makeRequestConfig = ({
  token,
  endpoint = '',
  isCurrentDomainEndpoint = true,
  middlewaresAreDisabled,
}: UpgradeCookieRequestType): IRequestParams => {
  const cookieUpgradeEndpoint = isCurrentDomainEndpoint
    ? UPGRADE_COOKIE_ENDPOINT
    : `${endpoint}${UPGRADE_COOKIE_ENDPOINT_PATH}`;

  return {
    translateFunction: () => '',
    endpoint: cookieUpgradeEndpoint,
    headers: {
      AuthorizeV3: token,
    },
    credentials: 'include',
    middlewaresAreDisabled,
  };
};
