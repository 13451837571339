const BASE_MESSAGE = 'Navigation error';

type ParamsType = {
  menuName?: string;
  navigateUrl?: string;
  routeName?: string;
};

export const getNavigationErrorMessage = ({
  menuName = '',
  routeName = '',
  navigateUrl = '',
}: ParamsType): string => {
  if (routeName) {
    return `${BASE_MESSAGE}, routeName: ${routeName}`;
  }
  if (navigateUrl) {
    return `${BASE_MESSAGE}, navigateUrl: ${navigateUrl}`;
  }
  if (menuName) {
    return `${BASE_MESSAGE}, menuName: ${menuName}`;
  }

  return BASE_MESSAGE;
};
