import { IAdvancedStore } from '@portals/redux-core-modules';
import { Router } from 'router5';

type ParamsType = {
  store: IAdvancedStore;
  router: Router;
};

export const fetchExternalPackages = async ({ store, router }: ParamsType) => {
  try {
    await import('@root/suppliers-portal-external-packages').then(
      ({
        externalPackages,
        registrationSaga,
        registrationReducer,
        registrationDependencies,
        registrationActions,
      }) => {
        if (externalPackages) {
          store.setDependencies({
            ...store.dependencies,
            externalPackages: {
              ...store.dependencies?.externalPackages,
              ...externalPackages,
            },
          });
        }

        if (registrationActions) {
          registrationActions();
        }

        if (registrationSaga) {
          registrationSaga({ store });
        }

        if (registrationReducer) {
          registrationReducer({ store });
        }

        if (registrationDependencies) {
          registrationDependencies({ store, router });
        }
      }
    );

    return;
  } catch (error: any) {
    console.error(
      'fetchExternalPackagesWorkerSaga get an error',
      error.message
    );
  }
};
