import { Action, BaseAction, ModulesListType } from './types';

export const FETCH_PLATFORM_MODULES_SAGA =
  '@platform-modules/FETCH_PLATFORM_MODULES_SAGA';
export const fetchPlatformModulesActionSaga: BaseAction = () => ({
  type: FETCH_PLATFORM_MODULES_SAGA,
});

export const SET_PLATFORM_MODULES_DATA =
  '@platform-modules/SET_PLATFORM_MODULES_DATA';
export const setPlatformModulesAction: Action<{
  modules: ModulesListType;
}> = (payload) => ({
  type: SET_PLATFORM_MODULES_DATA,
  payload,
});

export const SET_LOADING_START = '@platform-modules/SET_LOADING_START';
export const setLoadingStartAction: BaseAction = () => ({
  type: SET_LOADING_START,
});

export const SET_LOADING_STOP = '@platform-modules/SET_LOADING_STOP';
export const setLoadingStopAction: BaseAction = () => ({
  type: SET_LOADING_STOP,
});
