import { IResponse, JSONRPCRequest } from '@portals/fetch-api';

type TMfaStateRequestParams = {
  endpoint: string;
  deviceId: string;
  middlewaresAreDisabled?: boolean;
};

export const mfaStateRequest = ({
  endpoint,
  deviceId,
  middlewaresAreDisabled,
}: TMfaStateRequestParams): Promise<IResponse> =>
  new JSONRPCRequest().makeRequest({
    endpoint,
    body: {
      params: {
        deviceID: deviceId,
      },
    },
    middlewaresAreDisabled,
    credentials: 'include',
  });
