import { verifyExistingToken } from '../../../_utils/auth-v3/verify-existing-token';
import { asyncSingleton } from '../../../_utils/auth-v3/async-singleton';
import { getNewToken } from '../../../_utils/auth-v3/get-new-token';
import { RefreshTokenCookieUnavailable } from '../../../_utils/auth-v3/_common/referesh-token-cookie-unavailable';

const authViaV3Raw = async () => {
  if (!window.ENVS.ENABLE_AUTH_V3) {
    throw new RefreshTokenCookieUnavailable();
  }

  return verifyExistingToken()
    .catch(() => getNewToken())
    .catch((error: any) => {
      console.error(error);
      // TODO temporary fix
      throw new RefreshTokenCookieUnavailable();
    });
};

export const authViaV3 = () => asyncSingleton('authViaV3', authViaV3Raw);
