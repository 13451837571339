/* eslint-disable no-underscore-dangle */

import {
  fetchUserInfoAction,
  getFormattedFilteredFeatures,
  loadUserPermissionsRequest,
  setUserPermissionsAction,
} from '@portals/service-user';
import { IAdvancedStore } from '@portals/redux-core-modules';
import { batchActions } from 'redux-batched-actions';
import {
  geti18Next,
  getLocale,
  setLocale,
  setHeadLang,
  setLanguagesListAction,
  setWildcardLocaleCookie,
} from '@portals/i18next-utils';
import { IResponse } from '@portals/fetch-api';
import { fetchManifestActionSaga } from '@portals/service-pwa';
import { Router } from 'router5';
import { fetchMenuActionSaga } from '@portals/service-products';
import { selectedSupplierIdSelector } from '@portals/databus-service-suppliers';
import {
  setSupplierId as setSupplierIdForDigitalFingerPrint,
  setUserDataForDigitalFingerPrint,
} from '@portals/fingerprint-utils';
import {
  appNamespace,
  LAST_SUCCESSFUL_LOGIN_KEY,
  SELLER_PORTAL_ACCESS_TOKEN,
} from '../../_constants';
import { initDatabusSubscriptions } from '../../databus/init-databus-subscriptions';
import { fetchPlatformModulesActionSaga } from '../../_redux/platform-modules';
import {
  setCoreRequestsErrorAction,
  setCoreRequestsErrorCodeAction,
  setInitLoadingStartAction,
  setInitLoadingStopAction,
} from '../../_redux/app-state-module';
import { loginInstrospectRequest } from '../../api/requests/login-introspect';
import { manifestRequest } from '../../api/requests/manifest';
import { i18nextNSInit } from '../../_constants/i18next/i18next-init';
import { parseJwt } from '../../_utils/auth-v3/get-decode-jwt-token';
import { authViaV3 } from '../../_utils/auth-v3/auth-via-v3';
import { OFFLINE_ERROR_TEXT } from '../process-offline-code';
import { getIsNotProductionOrigin } from '../get-is-not-production-origin';
import { getAbacRequestParams } from '../get-abac-request-params';
import { getUserInfoCacheConfig } from '../api-cache/configs/user-info-cache-config';
import { getFetchMenuCacheKey } from '../api-cache/configs/get-fetch-menu-cache-key';
import { getMainMenuRequestConfig } from '../get-main-menu-request-config';
import { LocalStorageWorker } from '../localstorage-worker';
import { clearUserInfoCache } from '../api-cache/clear-user-info';
import { redirectToLoginPage } from '../redirect-to-login';
import { fetchUserPermissionsData } from '../init-app-utils/fetch-permissions-data';
import { formatPermissionsAndDispatch } from './_utils/format-permissions-and-dispatch';
import { getCookie } from './_utils/get-cookie';
import { processIntrospectResponse } from './_utils/process-introspect-response';
import { fetchExternalPackages } from './_utils/fetch-external-packages';
import { setUserInfo } from './_utils/set-user-info';
import { formatPermissionsDataAndDispatch } from './_utils/format-permissions-data-and-dispatch';
import { currentZone } from '@portals/geo-utils';

const languagesFallback = window.LANGUAGES_FALLBACK;
const ROOT_ABAC_FEATURES = (window.ENVS.ROOT_ABAC_FEATURES ||
  []) as Array<string>;
const isNotProductionOrigin = getIsNotProductionOrigin();

const I18N_ERROR_TEXT = 'I18N_ERROR_TEXT';

const WILDCARD_DOMAIN = currentZone;

// eslint-disable-next-line no-underscore-dangle
const ROOT_URLS = window.__ROOT_URLS__;

type ParamsType = {
  store: IAdvancedStore;
  router: Router;
};

export const initApp = async ({
  store: { dispatch },
  store,
  router,
}: ParamsType) => {
  const locale = getLocale({ isFromCookie: true });
  let userId: any;
  let sessionId: any;

  try {
    // запускаем первичный флаг загрузки
    dispatch(setInitLoadingStartAction());
    // если нет токена в локальном сторадже делаем запрос
    try {
      await authViaV3();
    } catch (authViaV3Error) {
      console.error('error in authViaV3: ', authViaV3Error);
      if (window.ENVS.DISABLE_PASSPORT_FALLBACK) {
        throw authViaV3Error;
      }
    }
    // инициируем все подписки Databus
    initDatabusSubscriptions(store);

    // устанавливаем локаль приложения
    setLocale({ locale, isFromCookie: true });

    // устанавливаем lang в <head />
    setHeadLang(locale);

    // устанавливаем wildcardlocal локаль
    setWildcardLocaleCookie({ locale, domain: WILDCARD_DOMAIN });
    if (process.env.NODE_ENV === 'development') {
      setWildcardLocaleCookie({ locale, domain: '' });
    }

    // инициализируем i18next
    await geti18Next({ appNamespace, locale, debug: false });

    await i18nextNSInit(locale);

    if (languagesFallback) {
      dispatch(setLanguagesListAction(languagesFallback));
    }

    const accessToken = localStorage.getItem(SELLER_PORTAL_ACCESS_TOKEN) || '';
    // декодируем полученый токен
    const decodeAccessToken = parseJwt(accessToken);

    // забираем данные юзера чтобы использовать id юзера как часть кэш-ключа в запросах
    if (!decodeAccessToken) {
      const [introspectResponse] = (await Promise.all([
        loginInstrospectRequest({}),
        fetchExternalPackages({ store, router }),
      ])) as unknown as Array<IResponse>;

      await processIntrospectResponse({
        response: introspectResponse,
        isNotProductionOrigin,
      });
      userId =
        introspectResponse.data?.userID ||
        Number(LocalStorageWorker.getItem(`${appNamespace}.id`) ?? 0);
      sessionId = introspectResponse.data?.sessionID || '';
    } else {
      await fetchExternalPackages({ store, router });
      userId = decodeAccessToken?.user;
      sessionId = decodeAccessToken?.session_id;
      LocalStorageWorker.setItem(`${appNamespace}.id`, userId);
    }

    // инитим скрипт цифрового отпечатка (делаем это тут, потому что нам нужен sessionId из ответа loginInstrospectRequest)
    setUserDataForDigitalFingerPrint({
      sessionId,
      userId,
      locale,
    });

    // NOTE
    // Выглядит как костыль, но databus дает подписаться на событие один раз
    // Поэтому ловим событие через window
    window.addEventListener('@data-subscriber/selected-supplier', () => {
      const supplierId = selectedSupplierIdSelector();
      setSupplierIdForDigitalFingerPrint(supplierId);
    });

    LocalStorageWorker.setItem(LAST_SUCCESSFUL_LOGIN_KEY, String(Date.now()));

    const featuresFilterList = getFormattedFilteredFeatures(ROOT_ABAC_FEATURES);

    const initialAbacParams = getAbacRequestParams({
      getCookie,
      strategy: 'StaleWhileRevalidate',
      userId,
      featuresFilterList,
    });

    const supplierId = initialAbacParams.key
      ? `${initialAbacParams.key}`
      : null;

    const userPermissionDataParams = {
      supplierId,
      userId,
      onUpdateCache: (response: any) =>
        formatPermissionsDataAndDispatch({
          supplierId,
          store,
          responseData: response?.data,
          // нет гарантии, что данные придут когда в датабасе еще пусто, поэтому лучше смержить
          withPermissionsMerge: true,
        }),
    };

    const [permissionsResponse, permissionsDataResponse] = await Promise.all([
      loadUserPermissionsRequest(initialAbacParams),
      fetchUserPermissionsData(userPermissionDataParams),
    ]);

    const { data: permissions } = permissionsResponse || {
      data: {},
      error: true,
    };

    formatPermissionsAndDispatch({
      dispatch: store.dispatch,
      permissions,
    });

    formatPermissionsDataAndDispatch({
      supplierId,
      store,
      responseData: permissionsDataResponse,
    });

    setUserInfo({
      userInfo: { id: userId },
      dispatch: store.dispatch,
    });

    // намеренно не сделан батчинг - тут нужна производительность.
    // запрашиваем все платформенные модули
    dispatch(fetchPlatformModulesActionSaga());

    store.dispatch(
      fetchUserInfoAction({
        browserCacheParams: getUserInfoCacheConfig({
          userId,
          onUpdateCache: ({ data }) => {
            setUserInfo({ userInfo: data.user, dispatch: store.dispatch });
          },
          strategy: 'StaleWhileRevalidate',
        }),
      })
    );

    dispatch(
      fetchManifestActionSaga({
        request: manifestRequest,
      })
    );

    const purePermissions = permissions?.features?.permitted ?? {};

    const fetchMenuCacheKey = getFetchMenuCacheKey({ getCookie }).key;
    const fetchMenuConfig = getMainMenuRequestConfig({
      cacheKey: fetchMenuCacheKey,
      strategy: 'StaleWhileRevalidate',
      userId,
      menuFromFileRequest:
        !('menuTakenFromFile' in purePermissions) ||
        purePermissions.menuTakenFromFile,
      // feature to switch default merging
      // todo rename feature
      defaultShown:
        !('activeSupplier' in purePermissions) ||
        purePermissions.activeSupplier,
    });

    dispatch(
      fetchMenuActionSaga({
        permissions: purePermissions,
        excludeEndpoinds: ROOT_URLS,
        callbackFinallyLoad: async ({ loadProductsStopAction }) => {
          dispatch(
            batchActions([loadProductsStopAction(), setInitLoadingStopAction()])
          );
        },
        // если ошибка забора модулей то выключаем приложение
        callbackOnError: ({ loadProductsStopAction, errorData }) => {
          const requestId = (errorData.headers['x-request-id'] as string) || '';

          dispatch(
            batchActions([
              loadProductsStopAction(),
              setCoreRequestsErrorAction(),
              // setHealthCheckAction(),
              setCoreRequestsErrorCodeAction(requestId),
              setInitLoadingStopAction(),
            ])
          );
        },
        configs: fetchMenuConfig,
      })
    );
  } catch (error: any) {
    console.error('error when initialize ROOT: ', error.message);

    if (error.message === I18N_ERROR_TEXT) {
      return;
    }

    if (error.message === OFFLINE_ERROR_TEXT) {
      // запрашиваем все платформенные модули для того
      // чтобы в оффлайне показать страницу кастомной ошибки
      // в блоке finally сделать не можем ибо надо осуществить запросы ДО того как
      // будет загружены данные о меню

      dispatch(
        batchActions([
          fetchPlatformModulesActionSaga(),
          // завершаем первичный флаг загрузки - в остальных случаях будет редирект на логин
          setInitLoadingStopAction(),
          setUserPermissionsAction({
            permissions: { [window.ENVS.MAIN_ABAC_SCOPE]: {} },
          }),
          setCoreRequestsErrorAction(),
          // setHealthCheckAction(),
        ])
      );

      return;
    }

    clearUserInfoCache();

    redirectToLoginPage(locale);
  }
};
