import {
  PermissionsDataItemType,
  TAbExperiment,
} from '@portals/databus-service-user';

export const formatDataToSpits = (permissionsData: PermissionsDataItemType) => {
  try {
    if (!permissionsData || typeof permissionsData !== 'object') {
      return;
    }

    return Object.keys(permissionsData).reduce<Record<string, unknown>>(
      (acc, value) => {
        const { data, decision } = permissionsData[
          value as keyof typeof permissionsData
        ] as TAbExperiment;

        if (data && data?.expID) {
          acc[data.expID] = {
            ...data,
            decision: Boolean(decision),
          };
        }

        return acc;
      },
      {}
    );
  } catch (error) {
    console.error('Error in formatDataToSpits', error);

    return {};
  }
};
