import Joi from 'joi';
import { IResponse, PureRestRequest } from '@portals/fetch-api';
import { INTROSPECT_LOCAL_ENDPOINT } from '../../../api/endpoints/passport';
import { extraVerifyNotAuthRetry } from '../_utils/extra-verify-not-auth-retry';

export type ParamsType = {
  middlewaresAreDisabled?: boolean;
};

export const loginInstrospectRequest = ({
  middlewaresAreDisabled,
}: ParamsType): Promise<IResponse> =>
  new PureRestRequest().getRequest({
    endpoint: INTROSPECT_LOCAL_ENDPOINT,
    isErrorTextStraightToOutput: true,
    credentials: 'include',
    responseSchema: Joi.object({
      error: Joi.string(),
      sessionID: Joi.string(),
      userID: Joi.number(),
    }),
    retry: 2,
    extraVerifyRetry: extraVerifyNotAuthRetry,
    tracingDisabled: true,
    middlewaresAreDisabled,
    notRetryWhenOffline: true,
    customTimeout: 10000,
  });
