import { Dispatch } from 'redux';
import { take, fork, cancel } from 'redux-saga/effects';
import {
  RESET_HEALTHCHECK_ACTION,
  SET_HEALTHCHECK_ACTION,
} from '../../actions';
import { healthCheckWorkerSaga } from './healtcheck-worker-saga';

export const HEALTH_CHECK_SAGA_NAME = 'HEALTH_CHECK_SAGA_NAME';

type ParamsType = {
  dispatch: Dispatch;
};

export function* healthCheckWatcherSaga({ dispatch }: ParamsType) {
  // eslint-disable-next-line
  // @ts-ignore
  while (yield take(SET_HEALTHCHECK_ACTION)) {
    // eslint-disable-next-line
    // @ts-ignore
    const task = yield fork(healthCheckWorkerSaga, { dispatch });

    yield take(RESET_HEALTHCHECK_ACTION);
    yield cancel(task);
  }
}
