import { downgradeCookieRequest } from '../../../api/requests/downgrade-cookie';
import { getSanitizedList } from '../downgrade-list';

export const downgradeCookies = async () => {
  const list = getSanitizedList(window.LOGOUT_REQUIRED_EXTERNAL_ORIGINS);
  const externalRequests = list.map((endpoint: string) =>
    downgradeCookieRequest({ endpoint, isCurrentDomainEndpoint: false })
  );

  const currentDomainRequest = downgradeCookieRequest({
    isCurrentDomainEndpoint: true,
  });

  await Promise.allSettled([...externalRequests, currentDomainRequest]);

  return true;
};
