import { IAdvancedStore } from '@portals/redux-core-modules';
import {
  setPwaPromptIosShownAction,
  setPwaPromptAndroidEventAction,
} from '@portals/service-pwa';
import {
  isIOS,
  isChrome,
  isYandex,
  isEdge,
  isDesktop,
  isSafari,
} from 'react-device-detect';
import { detectIsPWA } from '../_utils/detect-pwa';

export const setPwaPrompt = ({ dispatch }: IAdvancedStore) => {
  const isPWA = detectIsPWA();

  if (isPWA) {
    return;
  }

  // not listen on ios - just set prompt always
  if (isIOS && isSafari) {
    dispatch(setPwaPromptIosShownAction(true));

    return;
  }

  if (isChrome || (isYandex && isDesktop) || (isEdge && isDesktop)) {
    window.addEventListener('beforeinstallprompt', (event) => {
      // Prevent the mini-infobar from appearing on mobile
      event.preventDefault();

      dispatch(setPwaPromptAndroidEventAction(event));
    });

    // after install - hide install button
    window.addEventListener('appinstalled', () => {
      dispatch(setPwaPromptAndroidEventAction(null));
    });
  }
};
