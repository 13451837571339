import { performanceMonitor } from '@portals/wb-front-performance-tracker';
import ttiPolyfill from 'tti-polyfill';
import { pruneApiCaches } from './prune-api-caches';

const isProduction = process.env.NODE_ENV === 'production';

export const initPerformanceMetrics = async () => {
  performanceMonitor.init({});

  performanceMonitor.addMeasurement({
    name: 'Domain',
    measurementData: {
      duration: window.origin,
    },
  });

  const tti: number | null =
    await ttiPolyfill.getFirstConsistentlyInteractive();

  // safari and firefox can not measure tti
  if (tti && 'PerformanceLongTaskTiming' in window) {
    performanceMonitor.addMeasurement({
      name: 'TimeToInteractive',
      measurementData: {
        duration: tti,
      },
    });

    performanceMonitor.sendMeasurements();
  }

  // очистка кэша тут тк она не должна не влиять на первую загрузку
  await pruneApiCaches();
};

export const appLoadedMeasureSendCallback = () => {
  // safari and firefox can not measure tti
  if (!('PerformanceLongTaskTiming' in window) && isProduction) {
    performanceMonitor.sendMeasurements();

    // если заходим пост загрузку
    // const isDesktop = getIsDesktop();
    // if (isDesktop) {
    // setFetchProductsEvent({
    //   getMenuDataFromStore: true,
    //   withoutLoadingIndicator: true,
    //   loadAllModules: true,
    // });
    // }
  }
};
