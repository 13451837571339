import { authViaV3 } from '../../../../../../../_utils/auth-v3/auth-via-v3';
import { asyncSingleton } from '../../../../../../../_utils/auth-v3/async-singleton';
import { RefreshTokenCookieUnavailable } from '../../../../../../../_utils/auth-v3/_common/referesh-token-cookie-unavailable';
import { oldWayLogin } from '../old-way-login';

export const loginApi = () => {
  return asyncSingleton('loginApi', () =>
    authViaV3().catch((error) => {
      if (error instanceof RefreshTokenCookieUnavailable) {
        return oldWayLogin().then(() => true);
      }

      throw error;
    })
  );
};
