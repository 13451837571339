import { Plugin, Router, State } from 'router5';
import { IRouterDependencies } from '@portals/service-router';
import { setFirstRouteTransitionFinishedAction } from '../../_redux/app-state-module';

export const transitionPlugin = (router?: Router): Plugin => {
  const { store } = router?.getDependencies() as IRouterDependencies;

  return {
    onTransitionSuccess(toState?: State, fromState?: State) {
      // если только зашли или уходим
      // если двигаемся внутри одного приложения
      if (toState && !fromState) {
        store.dispatch(setFirstRouteTransitionFinishedAction());
      }
    },
  };
};
