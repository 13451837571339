import { asyncSingleton } from '../../../../../../../_utils/auth-v3/async-singleton';
import { authViaV3 } from '../../../../../../../_utils/auth-v3/auth-via-v3';
import { upgradeExternalDomain } from '../../../../../../../_utils/auth-v3/upgrade-external-domain';
import { RefreshTokenCookieUnavailable } from '../../../../../../../_utils/auth-v3/_common/referesh-token-cookie-unavailable';
import { oldWayExternalLogin } from '../old-way-external-login';

export const loginExternalApi = (apiBaseUrl: string) => {
  return asyncSingleton(apiBaseUrl, () =>
    authViaV3()
      .then(() => upgradeExternalDomain(apiBaseUrl))
      .catch((error) => {
        if (error instanceof RefreshTokenCookieUnavailable) {
          return oldWayExternalLogin(apiBaseUrl).then(() => true);
        }

        throw error;
      })
  );
};
