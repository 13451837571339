/* eslint-disable no-console */
import { ProjectCachesType } from './_types';

type ParamsType = {
  projectStorages: ProjectCachesType;
  prunedCaches: ProjectCachesType;
};

export const pruneProjectStorages = async ({
  projectStorages,
  prunedCaches,
}: ParamsType) => {
  const sortedProjectStorages = projectStorages.sort(
    (prev, next) => next.timestamp - prev.timestamp
  );

  // оставляем только 3 последних версии
  const oldProjectStorages = sortedProjectStorages.slice(3);

  if (oldProjectStorages.length) {
    prunedCaches.push(...oldProjectStorages);
  }

  // удаляем старое
  await Promise.allSettled(
    oldProjectStorages.map(async ({ storageName }) => {
      try {
        await caches.delete(storageName);
      } catch (error) {
        console.error(error);
      }
    })
  );
};
