import { checkIsCurrentDomain } from '../check-is-current-domain';
import { externalApiPathRegex } from '../external-api-path-regex';

export const checkIsExternalApiRequest = (endpoint: string | undefined) => {
  if (!endpoint) {
    return false;
  }

  return !checkIsCurrentDomain(endpoint) || externalApiPathRegex.test(endpoint);
};
