/* eslint-disable import/no-cycle */
import { take, fork } from 'redux-saga/effects';
import { Router } from 'router5';
import { IAdvancedStore } from '@portals/redux-core-modules';
import { Dispatch } from 'redux';
import { FETCH_PLATFORM_MODULES_SAGA } from '../../actions';
import { fetchPlatformModulesWorkerSaga } from './fetch-platform-modules-worker-saga';

export const FETCH_PLATFORM_MODULES_WATCHER_SAGA_NAME =
  'FETCH_PLATFORM_MODULES_WATCHER_SAGA_NAME';

export function* fetchPlatformModulesWatcherSaga({
  router,
  store,
}: {
  router: Router;
  store: IAdvancedStore;
  dispatch: Dispatch;
}) {
  while (true) {
    yield take(FETCH_PLATFORM_MODULES_SAGA);

    yield fork(fetchPlatformModulesWorkerSaga, { router, store });
  }
}
