import React, { useEffect, useState, memo, FC, useCallback } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router5';
import { connect } from 'react-redux';
import {
  getProductsMenuIsLoading,
  StatePartProducts,
} from '@portals/service-products';
import {
  getUserInfoIsLoading,
  getUserPermissionsIsLoading,
  StatePartUser,
} from '@portals/service-user';
import { BaseAction } from '@portals/redux-core-modules';
import {
  getPlatformModulesData,
  getPlatformModulesIsLoading,
  ModulesListType,
  StatePartPlatformModules,
} from '../../_redux/platform-modules';
import {
  getTranslationIsLoadingSelector,
  StatePartAppState,
  initLoadingSelector,
  logoutAction,
  loginLoadingSelector,
  coreRequestsErrorSelector,
  coreRequestsErrorCodeSelector,
  firstRouteTransitionFinishedSelector,
  logoutIsLoadingSelector,
} from '../../_redux/app-state-module';
import { LayoutView } from './_components/layout-view';
import { getIsLayoutLoaded } from './_utils/get-is-layout-loaded';

type PropsType = {
  userInfoIsLoading: boolean;
  userPermissionsIsLoading: boolean;
  productsMenuIsLoading: boolean;
  platformModules: ModulesListType;
  platformModulesIsLoading: boolean;
  translationIsLoading: boolean;
  rootInitLoading: boolean;
  loginLoading: boolean;
  logout: BaseAction;
  coreRequestsError: boolean;
  coreRequestsErrorCode: string;
  firstRouteTransitionFinished: boolean;
  appVersion: string;
  isLogoutLoading: boolean;
};

type ReduxStateType = StatePartProducts &
  StatePartUser &
  StatePartPlatformModules &
  StatePartAppState;

const WrappedContainer = memo(
  ({
    productsMenuIsLoading,
    userInfoIsLoading,
    platformModules,
    platformModulesIsLoading,
    userPermissionsIsLoading,
    translationIsLoading,
    rootInitLoading,
    logout,
    coreRequestsError,
    loginLoading,
    coreRequestsErrorCode,
    firstRouteTransitionFinished,
    appVersion,
    isLogoutLoading,
  }: PropsType) => {
    const [platformLoaded, setPlatformLoaded] = useState(false);

    const isLoading = getIsLayoutLoaded({
      userInfoIsLoading,
      userPermissionsIsLoading,
      productsMenuIsLoading,
      platformModulesIsLoading,
      platformLoaded,
      rootInitLoading,
      loginLoading,
    });

    useEffect(() => {
      if (!isLoading) {
        setPlatformLoaded(true);
      }
    }, [isLoading]);

    const handleLogout = useCallback(() => {
      logout();
    }, [logout]);

    return (
      <LayoutView
        appVersion={appVersion}
        coreRequestsError={coreRequestsError}
        coreRequestsErrorCode={coreRequestsErrorCode}
        firstRouteTransitionFinished={firstRouteTransitionFinished}
        handleLogout={handleLogout}
        isLogoutLoading={isLogoutLoading}
        platformLoaded={platformLoaded}
        platformModules={platformModules}
        translationIsLoading={translationIsLoading}
      />
    );
  }
);

const mapStateToProps = (state: ReduxStateType) => ({
  // TODO fix never
  userInfoIsLoading: getUserInfoIsLoading(state as never),
  // TODO fix never
  userPermissionsIsLoading: getUserPermissionsIsLoading(state as never),
  // TODO fix never
  productsMenuIsLoading: getProductsMenuIsLoading(state as never),
  platformModules: getPlatformModulesData(state),
  platformModulesIsLoading: getPlatformModulesIsLoading(state),
  translationIsLoading: getTranslationIsLoadingSelector(state),
  rootInitLoading: initLoadingSelector(state),
  loginLoading: loginLoadingSelector(state),
  coreRequestsError: coreRequestsErrorSelector(state),
  coreRequestsErrorCode: coreRequestsErrorCodeSelector(state),
  firstRouteTransitionFinished: firstRouteTransitionFinishedSelector(state),
  isLogoutLoading: logoutIsLoadingSelector(state),
});

const mapDispatchToProps = {
  logout: logoutAction,
};

export const ConnectedLayout = compose<FC>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(WrappedContainer);
