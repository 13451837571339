import { PureRestRequest } from '@portals/fetch-api';
import Joi from 'joi';

type ParamsType = {
  endpoint: string;
  version?: string;
  isFileRequest?: boolean;
};

export const i18nextRequest = ({
  endpoint,
  version = '',
  isFileRequest,
}: ParamsType) => {
  return new PureRestRequest().getRequest({
    extraValidationCallback: () => true,
    endpoint,
    parseType: 'json',
    customTimeout: 30000,
    responseSchema: Joi.object({
      translate: Joi.object().required(),
    }).required(),
    isErrorTextStraightToOutput: true,
    retry: 2,
    queryParams: { version },
    notRetryWhenOffline: true,
    pureJsonFileResponse: isFileRequest,
  });
};
