/* eslint-disable no-new-func */
import { sentryLogger } from '@portals/service-logger';
import { appNamespace } from '../../../_constants';

type ParamsType = {
  getCookie: (cookieName: string) => string;
};

type OuputType = {
  key: string;
};

export const getFetchMenuCacheKey = ({ getCookie }: ParamsType): OuputType => {
  const initialSerializedFunction = window.FETCH_MENU_CACHE_KEY_FUNCTION;

  if (initialSerializedFunction) {
    try {
      const parsedFunction = new Function(
        `return ${initialSerializedFunction}`
      )();

      return {
        ...parsedFunction({ getCookie }),
      };
    } catch (error) {
      console.error('error in getFetchMenuCacheKey function', error);

      sentryLogger.sendError({
        error,
        project: appNamespace,
        message: 'error in getFetchMenuCacheKey function',
      });

      return {
        key: '',
      };
    }
  }

  return {
    key: '',
  };
};
