import { IResponse, PureRestRequest } from '@portals/fetch-api';

export const loadModuleRequest = async (
  endpoint: string,
  fromCDN?: boolean
): Promise<IResponse> => {
  return new PureRestRequest().getRequest({
    mode: 'cors',
    parseType: 'text',
    endpoint,
    extraValidationCallback: () => true,
    isErrorTextStraightToOutput: true,
    pureJsonFileResponse: fromCDN,
    retry: 2,
    cache: fromCDN ? 'force-cache' : 'default',
  });
};
