import { OFFLINE_STATUS_CODE } from '@portals/fetch-api';
import { REQUEST_ERROR_STATUS_CODE } from '@portals/fetch-api/dist/constants';
import { getDeviceUser } from '../../../_utils/get-device-user';
import { OFFLINE_ERROR_TEXT } from '../../../_utils/process-offline-code';
import { transformSiteCookieRequest } from '../transform-site-cookie';
import { centralGrantAndLocalLogin } from './_utils/central-grant-and-local-login';
import { localGrantAndCentralLogin } from './_utils/local-grant-and-central-login';

const device = getDeviceUser();

type ParamsType = {
  middlewaresAreDisabled?: boolean;
};

export const loginWithSSORequest = async ({
  middlewaresAreDisabled,
}: ParamsType) => {
  try {
    await centralGrantAndLocalLogin({ middlewaresAreDisabled });
    await localGrantAndCentralLogin({ middlewaresAreDisabled });

    return true;
  } catch (error: any) {
    if (error.message === OFFLINE_ERROR_TEXT) {
      return true;
    }
    const offlineStatus =
      typeof navigator.onLine !== 'undefined' && !navigator.onLine;
    // do the tranformation of site's cookie
    const {
      error: transformCookieError,
      errorText: transformCookieErrorText,
      code: transformCookieCode,
      headers: transformCookieHeaders,
    } = await transformSiteCookieRequest({ device, middlewaresAreDisabled });
    if (
      offlineStatus ||
      transformCookieCode === OFFLINE_STATUS_CODE ||
      (transformCookieCode === REQUEST_ERROR_STATUS_CODE &&
        !Object.keys(transformCookieHeaders).length)
    ) {
      return true;
    }

    if (transformCookieError) {
      throw Error(transformCookieErrorText);
    }

    // if the tranformation success then make local grant and central login
    await localGrantAndCentralLogin({ middlewaresAreDisabled });

    return true;
  }
};
