/* eslint-disable no-console */
import { Plugin, State } from 'router5';

export const SWClientUpdatePlugin = (): Plugin => ({
  onTransitionStart: (toState: State, fromState: State): void => {
    if (
      (toState && fromState && toState.name === fromState.name) ||
      !fromState
    ) {
      return;
    }

    const coreRouteFromStateName = fromState?.name?.split?.('.')?.[0] ?? null;
    const coreRouteToStateName = toState?.name?.split?.('.')?.[0] ?? null;

    if (
      coreRouteToStateName !== coreRouteFromStateName &&
      coreRouteFromStateName &&
      coreRouteToStateName &&
      navigator.serviceWorker
    ) {
      navigator.serviceWorker
        .register('/sw.js')
        .then((reg) => reg.update())
        .catch((error) =>
          console.log('SWClientUpdatePlugin gets an error: ', error)
        );
    }
  },
});
