import { ExtraVerifyRetryCallbackType } from '@portals/fetch-api/dist/types';

export const extraVerifyNotAuthRetry: ExtraVerifyRetryCallbackType = ({
  formattedResponseData: { code, error },
}) => {
  if (!error) {
    return false;
  }

  if (code === 401) {
    return false;
  }

  return true;
};
