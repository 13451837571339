import { IResponse, JSONRPCRequest } from '@portals/fetch-api';

type TMfaValidateRequestParams = {
  endpoint: string;
  middlewaresAreDisabled?: boolean;
  params: { code: number; sticker: string };
};

export const mfaValidateRequest = ({
  endpoint,
  middlewaresAreDisabled,
  params,
}: TMfaValidateRequestParams): Promise<IResponse> =>
  new JSONRPCRequest().makeRequest({
    endpoint,
    body: {
      params,
    },
    middlewaresAreDisabled,
    credentials: 'include',
  });
