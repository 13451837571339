import { IResponse, PureRestRequest } from '@portals/fetch-api';
import { TRANSFORM_SITE_COOKIE_ENDPOINT } from '../../../api/endpoints/passport';
import { extraVerifyUpgradeNotAuthRetry } from './utils/extra-verify-upgrade-not-auth-retry';

type ParamsType = {
  device: string;
  middlewaresAreDisabled?: boolean;
};

export const transformSiteCookieRequest = ({
  device,
  middlewaresAreDisabled,
}: ParamsType): Promise<IResponse> =>
  new PureRestRequest().postRequest({
    endpoint: TRANSFORM_SITE_COOKIE_ENDPOINT,
    isErrorTextStraightToOutput: true,
    credentials: 'include',
    body: {
      device,
    },
    retry: 2,
    extraVerifyRetry: extraVerifyUpgradeNotAuthRetry,
    tracingDisabled: true,
    middlewaresAreDisabled,
    extraValidationCallback: ({ statusCode }) => statusCode === 200,
  });
