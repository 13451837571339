import { IRequestParams } from '@portals/fetch-api';
import Joi from 'joi';
import {
  DOWNGRADE_COOKIE_ENDPOINT,
  DOWNGRADE_COOKIE_ENDPOINT_PATH,
} from '../../../api/endpoints/passport';
import { DowngradeCookieRequestType } from './types';

export const makeRequestConfig = ({
  endpoint = '',
  isCurrentDomainEndpoint = true,
  middlewaresAreDisabled,
}: DowngradeCookieRequestType): IRequestParams => {
  const cookieDowngradeEndpoint = isCurrentDomainEndpoint
    ? DOWNGRADE_COOKIE_ENDPOINT
    : `${endpoint}${DOWNGRADE_COOKIE_ENDPOINT_PATH}`;

  return {
    endpoint: cookieDowngradeEndpoint,
    credentials: 'include',
    middlewaresAreDisabled,
    responseSchema: Joi.object({
      success: Joi.string().required(),
    }),
  };
};
