import { externalApiPathRegex } from '../external-api-path-regex';
import { currentZone } from '@portals/geo-utils';

const splitEndpoint = (endpoint: string) => {
  try {
    const { origin, pathname } = new URL(endpoint);

    return {
      origin,
      pathname,
    };
  } catch (error) {
    return {
      origin: '',
      pathname: endpoint,
    };
  }
};

const currentZoneRegex = new RegExp(`${currentZone}$`);

export const getExternalApiEndpoint = (endpoint: string) => {
  const { origin, pathname } = splitEndpoint(endpoint);
  const match = externalApiPathRegex.exec(pathname);

  if (origin && !currentZoneRegex.test(origin)) {
    return '';
  }

  if (match) {
    const [, externalApiPath] = match;

    return origin + externalApiPath;
  }

  return origin;
};
