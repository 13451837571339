import { InterceptorsController } from '@portals/fetch-api';
import { resolveUrl } from '@portals/geo-utils';

export const initGeoHosts = () => {
  new InterceptorsController().addRequestInterceptor((r: any) => {
    r.endpoint = resolveUrl(r.endpoint);

    return r;
  });
};
