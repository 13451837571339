import { fork, take, delay } from 'redux-saga/effects';
import { FETCH_LOGIN_NAVIGATION } from '../actions';
import { loginNavigationWorkerSaga } from './login-worker-saga';

/* eslint-disable no-cond-assign */

export const LOGIN_NAVIGATION_SAGA_NAME = 'LOGIN_NAVIGATION_SAGA_NAME';

export function* loginNavigationWatcherSaga() {
  // eslint-disable-next-line
  // @ts-ignore
  while (yield take(FETCH_LOGIN_NAVIGATION)) {
    // because of lacks of type in sagas

    yield fork(loginNavigationWorkerSaga);

    yield delay(100);
  }
}
