import translateEN from './en.json';
import translateRU from './ru.json';
import translateCN from './cn.json';
import translateTR from './tr.json';
import translateKA from './ka.json';

export const I18N_DICTIONARY: Record<string, any> = {
  en: translateEN,
  ru: translateRU,
  cn: translateCN,
  zh: translateCN,
  tr: translateTR,
  ka: translateKA,
};
