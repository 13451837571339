/* eslint-disable no-underscore-dangle */
import { call } from 'redux-saga/effects';
import { getLocale } from '@portals/i18next-utils';
import { OFFLINE_STATUS_CODE } from '@portals/fetch-api';
import { REQUEST_ERROR_STATUS_CODE } from '@portals/fetch-api/dist/constants';
import { loginInstrospectRequest } from '../../../api/requests/login-introspect';
import { loginWithSSORequest } from '../../../api/requests/login-with-sso';
import { redirectToLoginPage } from '../../../_utils/redirect-to-login';
import { clearUserInfoCache } from '../../../_utils/api-cache/clear-user-info';
import { authViaV3 } from '../../../_utils/auth-v3/auth-via-v3';
import { RefreshTokenCookieUnavailable } from '../../../_utils/auth-v3/_common/referesh-token-cookie-unavailable';

// если только зашли или уходим
// если двигаемся внутри одного приложения
// если не toState.meta.options.replace
export function* loginNavigationWorkerSaga() {
  const locale = getLocale({ isFromCookie: true });

  try {
    // проверяем локальную сессию
    try {
      yield call(authViaV3);
    } catch (authError) {
      // если в оффлайне то не редиректить пользователя
      if (typeof navigator.onLine !== 'undefined' && !navigator.onLine) {
        return;
      }

      if (!(authError instanceof RefreshTokenCookieUnavailable)) {
        throw authError;
      }

      const { error, code, headers } = yield call(loginInstrospectRequest, {});

      if (
        !error ||
        code === OFFLINE_STATUS_CODE ||
        (code === REQUEST_ERROR_STATUS_CODE && !Object.keys(headers).length)
      ) {
        return;
      }

      // попытка SSO
      yield call(loginWithSSORequest, {});
    }
  } catch {
    clearUserInfoCache();

    redirectToLoginPage(locale);
  }
}
