import { OFFLINE_STATUS_CODE } from '@portals/fetch-api';

type ParamsType = {
  responseBodyJson: string;
  code: number;
};

export const getIsNotLogined = ({ responseBodyJson, code }: ParamsType) => {
  // check offline and self-domain
  if (code === OFFLINE_STATUS_CODE) {
    return false;
  }

  return (
    code === 401 ||
    responseBodyJson.indexOf('empty:user id') !== -1 ||
    responseBodyJson.indexOf('unauthorized') !== -1 ||
    responseBodyJson.indexOf('authorization error') !== -1 ||
    responseBodyJson.indexOf('Не Авторизован') !== -1
  );
};
