/* eslint-disable import/no-cycle */
import { put } from 'redux-saga/effects';
import { Router } from 'router5';
import { IAdvancedStore } from '@portals/redux-core-modules';
// import { loadModule } from '@portals/service-modules-load';
import { ModulesListType } from '../../types';
import {
  setLoadingStartAction,
  setLoadingStopAction,
  setPlatformModulesAction,
} from '../../actions';
// import { loadScript } from '../../../../api/requests/load-script/index';
// import { loadStyle } from '../../../../api/requests/load-style/index';
// import { registerLayout } from '@root/suppliers-portal-layout';

export function* fetchPlatformModulesWorkerSaga({
  router,
  store,
}: {
  router: Router;
  store: IAdvancedStore;
}) {
  yield put(setLoadingStartAction());

  try {
    // eslint-disable-next-line no-underscore-dangle
    // const platformModules = window.__PLATFORM_MODULES__;
    const platformModules = [
      {
        name: 'Header',
        role: 'component',
        loadRef: {
          importFn: () =>
            import('@root/suppliers-portal-header') as Promise<{
              Component?: React.ComponentType;
              registrationSaga?: ({ store }: { store: IAdvancedStore }) => void;
              registrationReducer?: ({
                store,
              }: {
                store: IAdvancedStore;
              }) => void;
              registrationActions?: Array<
                ({
                  store,
                  router,
                }: {
                  store: IAdvancedStore;
                  router: Router;
                }) => void
              >;
              registrationRouterMiddlewares?: Array<() => void>;
              registrationRouterPlugins?: Array<() => void>;
            }>,
          JS_CDN: null,
          JS: null,
          CSS_CDN: null,
          CSS: null,
          APP_STATIC_NAMESPACE: null,
        },
      },
      {
        name: 'Layout',
        role: 'component',
        loadRef: {
          importFn: () => import('@root/suppliers-portal-layout'),
          JS_CDN: null,
          JS: null,
          CSS_CDN: null,
          CSS: null,
          APP_STATIC_NAMESPACE: null,
        },
      },
      {
        name: 'Content',
        role: 'component',
        loadRef: {
          importFn: () => import('@root/suppliers-portal-content'),
          JS_CDN: null,
          JS: null,
          CSS_CDN: null,
          CSS: null,
          APP_STATIC_NAMESPACE: null,
        },
      },
      {
        name: 'Footer',
        role: 'component',
        loadRef: {
          importFn: () => import('@root/suppliers-portal-footer'),
          JS_CDN: null,
          JS: null,
          CSS_CDN: null,
          CSS: null,
          APP_STATIC_NAMESPACE: null,
        },
      },
      {
        name: 'ErrorPage',
        role: 'component',
        loadRef: {
          importFn: () => import('@root/suppliers-portal-error-page'),
          JS_CDN: null,
          JS: null,
          CSS_CDN: null,
          CSS: null,
          APP_STATIC_NAMESPACE: null,
        },
      },
      {
        name: 'Preview',
        role: 'component',
        loadRef: {
          importFn: () => import('@root/suppliers-portal-preview'),
          JS_CDN: null,
          JS: null,
          CSS_CDN: null,
          CSS: null,
          APP_STATIC_NAMESPACE: null,
        },
      },
      {
        name: 'page-not-found',
        role: 'not-component',
        loadRef: {
          importFn: () => import('@root/suppliers-portal-page-not-found'),
          JS_CDN: null,
          JS: null,
          CSS_CDN: null,
          CSS: null,
          APP_STATIC_NAMESPACE: null,
        },
      },
    ];
    const modules: ModulesListType = {};

    yield Promise.all(
      platformModules.map(async ({ name, loadRef, role }) => {
        try {
          if (role === 'component') {
            const {
              Component,
              registrationSaga,
              registrationReducer,
              registrationActions,
              registrationRouterMiddlewares,
              registrationRouterPlugins,
            } = (await loadRef.importFn()) as any;

            if (registrationSaga) {
              registrationSaga({ store });
            }

            if (registrationReducer) {
              registrationReducer({ store });
            }

            if (registrationActions) {
              registrationActions.forEach((registrationAction: any) => {
                registrationAction({
                  store,
                  router,
                });
              });
            }

            if (registrationRouterMiddlewares) {
              registrationRouterMiddlewares.forEach((routerMiddleware: any) =>
                router.useMiddleware(routerMiddleware)
              );
            }

            if (registrationRouterPlugins) {
              registrationRouterPlugins.forEach(
                (registrationRouterPlugin: any) =>
                  router.usePlugin(registrationRouterPlugin)
              );
            }

            modules[name] = Component;
          } else {
            // если это полноценное приложение как модуль
            // // то и подключаем и парсим его как полноценный модуль
            const { routes } = (await loadRef.importFn()) as any;

            const { loadAction } = routes[0] || {};

            const { routes: oldRoutes } = router.getDependencies();

            router.setDependencies({
              routes: [...oldRoutes, ...routes],
            });

            router.add(routes);

            if (loadAction) {
              await loadAction();
            }
          }
        } catch (error) {
          console.error(`Load platform module "${name}" get an error`, error);
        }
      })
    );

    yield put(setPlatformModulesAction({ modules }));
  } catch (error: any) {
    console.error(
      'fetchPlatformModulesWatcherSaga get an error',
      error.message
    );
  } finally {
    yield put(setLoadingStopAction());
  }
}
