import { setUserDataAction } from '@portals/databus-service-user';
import {
  setUserInfoAction,
  setUserPermissionsAction,
} from '@portals/service-user';
import { Dispatch } from 'redux';

type ParamsType = {
  dispatch: Dispatch;
  userInfo: any;
};

export const setUserInfo = ({ dispatch, userInfo }: ParamsType) => {
  try {
    if (!userInfo) {
      return;
    }

    dispatch(setUserInfoAction({ userInfo }));
    setUserDataAction(userInfo);
  } catch (error) {
    dispatch(
      setUserPermissionsAction({
        permissions: { [window.ENVS.MAIN_ABAC_SCOPE]: {} },
      })
    );
  }
};
