import { analyticsEventEmitter } from '@portals/wb-front-analytics-tracker';
import {
  abExperimentsSelector,
  userDataSelector,
} from '@portals/databus-service-user';
import { getSupplierIdFromCookie } from '../get-supplier-id-from-cookie';
import { getCookie } from '../init-app/_utils/get-cookie';
import { formatDataToSpits } from '../ab-experiments';
import { getLocale } from '@portals/i18next-utils';

const APP_VERSION = process.env.NX_PUBLIC_APP_VERSION || 'v0.0.0';
const APP_BUILD_TIME = process.env.BUILD_TIME || '';

const additionalParamsGetter = () => {
  const abExperiments = abExperimentsSelector();

  const language = getLocale({ isFromCookie:true });

  return {
    idUser: userDataSelector().id,
    idSupplier: getSupplierIdFromCookie({ getCookie }).key,
    // для АБ пока отправляем строкой
    splits: JSON.stringify(formatDataToSpits(abExperiments)),
    uiRootVersion: APP_VERSION,
    uiRootBuildTime: APP_BUILD_TIME,
    language,
  };
};

export const initAnalytics = () => {
  const counterId = Number(window.ENVS.WB_ANALYTICS_COUNTER_ID);
  const cookieDomain = window.ENVS.WB_ANALYTICS_COOKIE_DOMAIN;

  analyticsEventEmitter.setAdditionalParamsGetter(additionalParamsGetter);

  const removeCookie = `_wbauid=; path=/; domain=${window.location.hostname}; expires=Thu, 01 Jan 1970 00:00:00 GMT`;

  // TODO: https://youtrack.wildberries.ru/issue/SPL-6439
  document.cookie = removeCookie;

  analyticsEventEmitter.init({ counterId, cookieDomain });
};
