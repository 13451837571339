import {
  LAST_SUCCESSFUL_LOGIN_KEY,
  SKIP_LANDING_GET_PARAMETER_NAME,
} from '../_constants';
import { LocalStorageWorker } from './localstorage-worker';

export const canRedirectoToLanding = () => {
  const HAS_SUCCESSFUL_LOGIN = !!LocalStorageWorker.getItem(
    LAST_SUCCESSFUL_LOGIN_KEY
  );

  const params = new URLSearchParams(document.location.search);
  const SKIP_LANDING = params.get(SKIP_LANDING_GET_PARAMETER_NAME) !== null;

  return (
    window.ENVS.ENABLE_PORTAL_LANDING && !HAS_SUCCESSFUL_LOGIN && !SKIP_LANDING
  );
};
