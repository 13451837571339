import { SELLER_PORTAL_ACCESS_TOKEN } from '../../../_constants';
import { slideRequest } from '../../../api/requests/slide';
import { slideConfirmRequest } from '../../../api/requests/slide-confirm';
import {
  AUTH_V3_NO_COOKIE_ERROR_RESULT,
  AUTH_V3_RESPONSE_SUCCESS_RESULT,
} from '../../../api/requests/_constants';
import { validateToken } from '../../../_utils/auth-v3/validate-token';
import { asyncSingleton } from '../../../_utils/auth-v3/async-singleton';
import { RefreshTokenCookieUnavailable } from '../../../_utils/auth-v3/_common/referesh-token-cookie-unavailable';

const getNewTokenRaw = async () => {
  const {
    data: { result, payload, error: slideErrorText },
  } = await slideRequest({ middlewaresAreDisabled: true });

  const accessToken = payload?.access_token;

  if (result !== AUTH_V3_RESPONSE_SUCCESS_RESULT) {
    if (result === AUTH_V3_NO_COOKIE_ERROR_RESULT) {
      throw new RefreshTokenCookieUnavailable();
    }

    throw new Error(slideErrorText);
  }

  const {
    data: { error: slideConfirmError, result: confirmResult },
  } = await slideConfirmRequest({
    sticker: payload?.sticker,
    middlewaresAreDisabled: true,
  });

  if (confirmResult !== AUTH_V3_RESPONSE_SUCCESS_RESULT) {
    throw new Error(slideConfirmError);
  }

  await validateToken(accessToken);

  localStorage.setItem(SELLER_PORTAL_ACCESS_TOKEN, accessToken);

  return true;
};

export const getNewToken = () => asyncSingleton('getNewToken', getNewTokenRaw);
