import { IResponse, OFFLINE_STATUS_CODE } from '@portals/fetch-api';
import { REQUEST_ERROR_STATUS_CODE } from '@portals/fetch-api/dist/constants';
import { loginWithSSORequest } from '../../../../api/requests/login-with-sso';
import { LocalStorageWorker } from '../../../../_utils/localstorage-worker';
import { appNamespace } from '../../../../_constants';

type ParamsType = {
  response: IResponse;
  isNotProductionOrigin: boolean;
};

export const processIntrospectResponse = async ({
  response,
  isNotProductionOrigin,
}: ParamsType) => {
  const { error, errorText, code, headers } = response;

  if (!error) {
    // save userId to work offline
    LocalStorageWorker.setItem(`${appNamespace}.id`, response.data.userID);

    return true;
  }

  // process offline
  if (
    // problems with .onLine method
    code === OFFLINE_STATUS_CODE ||
    (code === REQUEST_ERROR_STATUS_CODE && !Object.keys(headers).length)
  ) {
    return true;
  }

  // if not production origin - not do SSO at all
  if (isNotProductionOrigin) {
    throw Error(errorText);
  }

  await loginWithSSORequest({});

  return true;
};
