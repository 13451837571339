/* eslint-disable camelcase */
import {
  ProxyController,
  SetResponseTrackCallbackOptions,
} from '@portals/fetch-api';
import { sentryLogger } from '@portals/service-logger';

const SENTRY_REQUEST_ERROR_MESSAGE = 'Request error';

export const initRequestLoggers = () => {
  new ProxyController().setResponseTrackCallback({
    callback: (fetchLogParams: SetResponseTrackCallbackOptions) => {
      if (fetchLogParams.error) {
        sentryLogger.sendEvent({
          message: `${SENTRY_REQUEST_ERROR_MESSAGE} ${fetchLogParams.endpoint}`,
          tags: {
            'request custom exception': true,
            origin: window.origin,
            'request-url': fetchLogParams.endpoint,
            url: 'undefined',
          },
          responseData: fetchLogParams,
          level: 'Error',
          request: {
            url: fetchLogParams.endpoint,
            method: fetchLogParams.method as any,
            data: JSON.stringify(fetchLogParams.requestBody, null, 2),
            cookies: {
              all: fetchLogParams.requestCookies,
            },
            headers: fetchLogParams.requestHeaders,
          },
        });
      }
    },
    name: '@root/initRequestLoggers',
  });
};
