/* eslint-disable no-new-func */
import { getActivatedRoutes, IAdvancedRoute } from '@portals/service-router';
import { Router, Middleware } from 'router5';
import {
  findRouteFromList,
  getProductsData,
  MenuMainProductConfigListType,
} from '@portals/service-products';
import { IAdvancedStore } from '@portals/redux-core-modules';
import { fetchRuntimeConfigRequest } from '../../../api/requests/fetch-runtime-config';
import { getModulePath } from './_utils/get-module-path';

window.cachedWindowEnvs = new Map();

export const setWindowEndpointsMiddleware =
  (store: IAdvancedStore) =>
  (router: Router): Middleware =>
  async (toState, fromState) => {
    const { routes } = router.getDependencies();

    const activatedRoutes = getActivatedRoutes(toState, fromState, routes);

    await Promise.all(
      activatedRoutes.map(async (routeData: IAdvancedRoute) => {
        const windowEndpointsConfig = routeData?.params
          ?.endpointsConfig as unknown as Record<string, any>;

        const routeName = routeData.name;

        if (!windowEndpointsConfig) {
          return;
        }

        const { fromWindow, staticPath, customModulePath } =
          windowEndpointsConfig;

        if (!fromWindow) {
          return;
        }

        try {
          // получаем полный список сервисов
          const productsMenuItems: MenuMainProductConfigListType =
            getProductsData(store.getState() as never);

          const routeItem = productsMenuItems.find(
            findRouteFromList({ routeName })
          );

          const modulePath = getModulePath({
            modulePath: staticPath,
            customModulePath,
          });

          const serviceEnvs = routeItem?.config?.envs;

          if (window.cachedWindowEnvs.has(routeName)) {
            const cachedEnvs = window.cachedWindowEnvs.get(routeName);

            if (cachedEnvs) {
              const funcFromText = new Function(cachedEnvs);

              funcFromText();
            }

            return;
          }

          const runtimeRequest =
            Boolean(serviceEnvs) && serviceEnvs !== 'RUNTIME_ENVS'
              ? // если инлайн энвы то просто вернуть их
                { data: serviceEnvs, error: false, errorText: '' }
              : fetchRuntimeConfigRequest(modulePath);

          // RUNTIME_ENVS - это шаблон который должен быть заменен на переменные сервиса перед стартом пода
          const { error = false, errorText = '', data } = await runtimeRequest;

          if (error) {
            throw new Error(errorText);
          }

          const funcFromText = new Function(data);

          funcFromText();

          window.cachedWindowEnvs.set(routeName, data);
        } catch (error) {
          console.error('load endpoints from window error', error);
        }
      })
    );
  };
