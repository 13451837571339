/* eslint-disable no-underscore-dangle */

import Joi from 'joi';
import { PureRestRequest } from '@portals/fetch-api';
import { extraVerifyNotAuthRetry } from '../_utils/extra-verify-not-auth-retry';

type ParamsType = {
  endpoint: string;
  middlewaresAreDisabled?: boolean;
};

export const grantRequest = ({
  endpoint,
  middlewaresAreDisabled,
}: ParamsType): Promise<any> =>
  new PureRestRequest().postRequest({
    endpoint,
    body: {},
    responseSchema: Joi.object({
      error: Joi.string().allow(''),
      token: Joi.string(),
    }),
    isErrorTextStraightToOutput: true,
    credentials: 'include',
    retry: 2,
    extraVerifyRetry: extraVerifyNotAuthRetry,
    tracingDisabled: true,
    middlewaresAreDisabled,
  });
