import { loginInstrospectRequest } from '../../../../../../../api/requests/login-introspect';
import { loginWithSSORequest } from '../../../../../../../api/requests/login-with-sso';

export const oldWayLogin = async () => {
  // check local introspect
  const { error: introspectError } = await loginInstrospectRequest({
    middlewaresAreDisabled: true,
  });

  if (introspectError) {
    // check SSO
    await loginWithSSORequest({
      middlewaresAreDisabled: true,
    });
  }

  return true;
};
