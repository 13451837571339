import { IResponse } from '@portals/fetch-api';
import { loadUserPermissionBatchedRequest } from '@portals/service-user';
import { PermissionsDataItemType } from '@portals/databus-service-user';
import { getAbacPermissionsDataFeatures } from '../get-abac-permissions-data-features';
import { getAbacPermissionsCacheConfig } from '../api-cache/configs/abac-cache-config';
import { ABAC_PERMISSIONS_DATA_CACHE_KEY } from '../api-cache/_constants';

type TParams = {
  supplierId: string | null;
  userId: string;
  onUpdateCache: (data: any) => void;
};

type TOutput = Array<
  IResponse & {
    data: {
      decision: boolean;
      data?: PermissionsDataItemType;
    };
  }
> | null;

export const fetchUserPermissionsData = async ({
  userId,
  supplierId,
  onUpdateCache,
}: TParams): Promise<TOutput> => {
  try {
    const browserCacheParams =
      supplierId && userId
        ? {
            ...getAbacPermissionsCacheConfig({
              strategy: 'StaleWhileRevalidate',
              cacheKey: `${ABAC_PERMISSIONS_DATA_CACHE_KEY}-${supplierId}-${userId}`,
            }),
            onUpdateCache,
          }
        : null;

    const { data } = await loadUserPermissionBatchedRequest({
      features: getAbacPermissionsDataFeatures(supplierId || ''),
      browserCacheParams,
    });

    return data;
  } catch (error) {
    console.error('[fetchUserPermissionsData] get an error', error);

    return null;
  }
};
