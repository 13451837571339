/* eslint-disable no-console */
import { initGeoHosts } from './init-geo-hosts';
import { initRequestLoggers } from './init-request-loggers';
import { initRequestProxyVerify } from './init-request-proxy-verify';

export const initRequestProxies = async () => {
  console.log('@request-proxies init');

  initGeoHosts();
  initRequestLoggers();
  initRequestProxyVerify();
};
