import { ROOT_CACHE_STORAGE_NAME } from '../_utils/api-cache/_constants';
import { APP_NAMESPACE } from '../_constants/i18next/app-namespace';

export { appNamespace } from './app-namespace';
export { BROWSER_LIST } from './browser-list';
export { USER_DEVICE_LIST } from './user-device-list';

export const DEFAULT_API_CACHE_PARAMS = {
  storageCacheName: ROOT_CACHE_STORAGE_NAME,
  expires: 1000 * 60 * 60 * 24 * 60, // 60 days
  debug: true,
  // onCacheHit: sendApiCacheEventHit({
  //   projectName: appNamespace,
  // }),
  // onCacheMiss: sendApiCacheEventMiss({
  //   projectName: appNamespace,
  // }),
  disabledCache:
    // process.env.NODE_ENV === 'development' ||
    window.ENVS.DISABLED_API_CACHE === 'true',
};

export const SELLER_PORTAL_ACCESS_TOKEN = 'wb-eu-passport-v2.access-token';

export const CRITICAL_ACTION_CODE = 307;

export const SELLER_PORTAL_DOWNGRADE_LIST = 'wb-eu-portal.downgrade-list';

export const LAST_SUCCESSFUL_LOGIN_KEY = 'wb-eu-portal.last_successful_login';

export const SKIP_LANDING_GET_PARAMETER_NAME = 'skipLanding';

export const DefaultCriticalActionErrors = {
  incorrectCode: `${APP_NAMESPACE}:critical-confirm-modal.incorrectCode`,
  requestError: `${APP_NAMESPACE}:critical-confirm-modal.requestError`,
  abortEnterCodeError: `${APP_NAMESPACE}:critical-confirm-modal.abortEnterCodeError`,
  reachedLimit: `${APP_NAMESPACE}:critical-confirm-modal.reachedLimit`,
};
