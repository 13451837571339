import React, { memo } from 'react';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
import { Card } from './_components/card';

const cn = classnames.bind(styles);

const BLOCK_NAME = 'Error-page';

export const ErrorPageComponent = memo(() => (
  <div className={cn(BLOCK_NAME)}>
    <div className={cn(`${BLOCK_NAME}__card-wrapper`)}>
      <Card />
    </div>
  </div>
));
