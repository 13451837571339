import { SELLER_PORTAL_DOWNGRADE_LIST } from '../../../../_constants';
import { getListFromStorage } from '../get-list-from-storage';
import { parseList } from '../parse-list';

export const saveExternalApiPath = (apiPath: string) => {
  try {
    const currentList = parseList(getListFromStorage());
    currentList.push(apiPath);

    const uniqValues = currentList.reduce((acc, item) => {
      if (!acc.includes(item)) {
        acc.push(item);
      }

      return acc;
    }, [] as string[]);

    localStorage.setItem(
      SELLER_PORTAL_DOWNGRADE_LIST,
      JSON.stringify(uniqValues)
    );
    // eslint-disable-next-line no-empty
  } catch {}
};
