import translationsEN from './en.json';
import translationsRU from './ru.json';
import translationsCN from './cn.json';
import translationsTR from './tr.json';
import translationsKA from './ka.json';

const RU_LOCALE = 'ru';
const EN_LOCALE = 'en';
const CN_LOCALE = 'cn';
const ZH_LOCALE = 'zh';
const TR_LOCALE = 'tr';
const KA_LOCALE = 'ka';

export const ERROR_PAGE_TRANSLATIONS_MAP: Record<string, any> = {
  [RU_LOCALE]: translationsRU,
  [EN_LOCALE]: translationsEN,
  [CN_LOCALE]: translationsCN,
  [ZH_LOCALE]: translationsCN,
  [TR_LOCALE]: translationsTR,
  [KA_LOCALE]: translationsKA,
};
