import { OFFLINE_STATUS_CODE } from '@portals/fetch-api';
import { REQUEST_ERROR_STATUS_CODE } from '@portals/fetch-api/dist/constants';

export const OFFLINE_ERROR_TEXT = 'offline';

type ParamsType = {
  code: number;
  headers: any;
};

export const processOfflineRequestCode = ({ code, headers }: ParamsType) => {
  const offlineStatus =
    typeof navigator.onLine !== 'undefined' && !navigator.onLine;

  if (
    offlineStatus ||
    code === OFFLINE_STATUS_CODE ||
    (code === REQUEST_ERROR_STATUS_CODE && !Object.keys(headers).length)
  ) {
    throw new Error(OFFLINE_ERROR_TEXT);
  }
};
