export class LocalStorageWorker {
  static setItem = (key: string, value: any) => {
    try {
      if (typeof value === 'string') {
        return localStorage.setItem(key, value);
      }

      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error('failed setItem to local storage', error);
    }
  };

  static getItem = (key: string) => {
    const storageValue = localStorage.getItem(key);

    if (storageValue) {
      try {
        const result = JSON.parse(storageValue);

        return result;
      } catch {
        return null;
      }
    }

    return storageValue;
  };

  static deleteItem = (key: string) => {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error('error in deleteItem', error);
    }
  };
}
