import {
  AUTH_V3_NO_COOKIE_ERROR_RESULT,
  AUTH_V3_RESPONSE_SUCCESS_RESULT,
} from '../../../api/requests/_constants';
import { logoutV3Request } from '../../../api/requests/logout-v3';
import { RefreshTokenCookieUnavailable } from '../../../_utils/auth-v3/_common/referesh-token-cookie-unavailable';
import { downgradeCookies } from '../downgrade-cookies';

export const logoutViaV3 = async () => {
  if (!window.ENVS.ENABLE_AUTH_V3) {
    throw new RefreshTokenCookieUnavailable();
  }

  const {
    data: { result, error: logoutV3Error },
  } = await logoutV3Request();

  // Удалям куку WBTokenV3 даже если разлогин неудался
  // Иначе чел сможет заходить через introspect и т.д. через WBTokenV3
  await downgradeCookies();

  if (result !== AUTH_V3_RESPONSE_SUCCESS_RESULT) {
    // TODO temporary fix
    throw new RefreshTokenCookieUnavailable();
  }

  return true;
};
