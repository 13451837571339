import { Action, BaseAction } from '@portals/redux-core-modules';

export const SET_TRANSLATE_LOADING_START =
  '@app-state/SET_TRANSLATE_LOADING_START';
export const setTranslateLoadingStartAction: BaseAction = () => ({
  type: SET_TRANSLATE_LOADING_START,
});

export const SET_TRANSLATE_LOADING_STOP =
  '@app-state/SET_TRANSLATE_LOADING_STOP';
export const setTranslateLoadingStopAction: BaseAction = () => ({
  type: SET_TRANSLATE_LOADING_STOP,
});

export const SET_INIT_LOADING_START = '@app-state/SET_INIT_LOADING_START';
export const setInitLoadingStartAction: BaseAction = () => ({
  type: SET_INIT_LOADING_START,
});

export const SET_INIT_LOADING_STOP = '@app-state/SET_INIT_LOADING_STOP';
export const setInitLoadingStopAction: BaseAction = () => ({
  type: SET_INIT_LOADING_STOP,
});

export const SET_LOGIN_LOADING_START = '@app-state/SET_LOGIN_LOADING_START';
export const setLoginLoadingStartAction: BaseAction = () => ({
  type: SET_LOGIN_LOADING_START,
});

export const SET_LOGIN_LOADING_STOP = '@app-state/SET_LOGIN_LOADING_STOP';
export const setLoginLoadingStopAction: BaseAction = () => ({
  type: SET_INIT_LOADING_STOP,
});

export const LOGOUT = '@app-state/LOGOUT';
export const logoutAction: BaseAction = () => ({
  type: LOGOUT,
});

export const SET_CORE_REQUESTS_ERROR = '@app-state/SET_CORE_REQUESTS_ERROR';
export const setCoreRequestsErrorAction: BaseAction = () => ({
  type: SET_CORE_REQUESTS_ERROR,
});

export const RESET_CORE_REQUESTS_ERROR = '@app-state/RESET_CORE_REQUESTS_ERROR';
export const resetCoreRequestsErrorAction: BaseAction = () => ({
  type: RESET_CORE_REQUESTS_ERROR,
});

export const SET_CORE_REQUESTS_HEALTHCHECK_INTERVAL =
  '@app-state/SET_CORE_REQUESTS_HEALTHCHECK_INTERVAL';
export const sethealthCheckCoreRequestsIntervalAction: BaseAction = () => ({
  type: SET_CORE_REQUESTS_HEALTHCHECK_INTERVAL,
});
export const RESET_CORE_REQUESTS_HEALTHCHECK_INTERVAL =
  '@app-state/RESET_CORE_REQUESTS_HEALTHCHECK_INTERVAL';
export const resethealthCheckCoreRequestsIntervalAction: BaseAction = () => ({
  type: RESET_CORE_REQUESTS_HEALTHCHECK_INTERVAL,
});

export const SET_HEALTHCHECK_ACTION = '@app-state/SET_HEALTHCHECK_ACTION';
export const setHealthCheckAction: BaseAction = () => ({
  type: SET_HEALTHCHECK_ACTION,
});

export const INCREMENT_HEALTHCHECK_ACTION =
  '@app-state/INCREMENT_HEALTHCHECK_ACTION';
export const incrementHealthCheckIntervalAction: BaseAction = () => ({
  type: INCREMENT_HEALTHCHECK_ACTION,
});

export const RESET_HEALTHCHECK_ACTION = '@app-state/RESET_HEALTHCHECK_ACTION';
export const resetHealthCheckAction: BaseAction = () => ({
  type: RESET_HEALTHCHECK_ACTION,
});

export const SET_CORE_REQUESTS_ERROR_CODE =
  '@app-state/SET_CORE_REQUESTS_ERROR_CODE';
export const setCoreRequestsErrorCodeAction: Action<string> = (payload) => ({
  type: SET_CORE_REQUESTS_ERROR_CODE,
  payload,
});

export const RESET_CORE_REQUESTS_ERROR_CODE =
  '@app-state/RESET_CORE_REQUESTS_ERROR_CODE';
export const resetCoreRequestsErrorCodeAction: BaseAction = () => ({
  type: RESET_CORE_REQUESTS_ERROR_CODE,
});

export const SET_FIRST_ROUTE_TRANSITION_FINISHED =
  '@app-state/SET_FIRST_ROUTE_TRANSITION_FINISHED';
export const setFirstRouteTransitionFinishedAction: BaseAction = () => ({
  type: SET_FIRST_ROUTE_TRANSITION_FINISHED,
});

export const SET_LOGOUT_LOADING_START = '@app-state/SET_LOGOUT_LOADING_START';
export const setLogoutLoadingStartAction: BaseAction = () => ({
  type: SET_LOGOUT_LOADING_START,
});

export const SET_LOGOUT_LOADING_STOP = '@app-state/SET_LOGOUT_LOADING_STOP';
export const setLogoutLoadingStopAction: BaseAction = () => ({
  type: SET_LOGOUT_LOADING_STOP,
});
