import { IResponse } from '@portals/fetch-api';
import { loadModuleRequest } from '../api/load-module';
import { requireModule } from './require-module';

const cachedScripts = new Map();

type ParamsType = {
  name: string;
  staticURL: string;
  jsUrl: string;
  fromCDN?: boolean;
  cdnData?: {
    APP_STATIC_NAMESPACE?: string;
  };
  version?: string;
  externalPackages?: Record<string, any>;
};

export const loadModule = ({
  name,
  staticURL,
  jsUrl,
  fromCDN,
  cdnData: { APP_STATIC_NAMESPACE } = {},
  version = '',
  externalPackages = {},
}: ParamsType) => {
  const endpoint = fromCDN ? jsUrl : `${staticURL}/${jsUrl}`;

  let promise;

  if (cachedScripts.has(endpoint)) {
    promise = cachedScripts.get(endpoint);
  } else {
    promise = new Promise(async (resolve, reject) => {
      try {
        const { data, error, errorText } = (await loadModuleRequest(
          endpoint,
          fromCDN
        )) as IResponse & { data: string };

        if (error) {
          throw new Error(errorText);
        }

        // pathToReplaceRuntimeEnvs - publicPath for window env with "runtime-config.json"
        // publicPathForReplace - old publicPath for webpack react apps
        // AND for other tools that use relative path to service
        const source = data
          .replace(
            /pathToReplaceRuntimeEnvs/g,
            `/nsf/ui/${APP_STATIC_NAMESPACE}/umd/`
          )
          .replace(/appVersion/g, version)
          .replace(/publicPathForReplace/g, `${staticURL}/umd/`);

        // eslint-disable-next-line no-new-func
        const extensionFn = new Function(
          'exports',
          'require',
          'module',
          source
        );

        const module = {
          exports: {},
        };

        extensionFn(
          module.exports,
          requireModule.bind(null, externalPackages),
          module
        );

        resolve(module.exports);
      } catch (error) {
        console.error('fetching the route script ended with an error', error);
        // ToDo: need to research
        reject(error);
      }
    });

    cachedScripts.set(endpoint, promise);
  }

  return promise
    .then((component: any) => {
      if (component) {
        return component;
      }

      console.error(`"${name}" was not created by "${endpoint}"`);
      cachedScripts.delete(endpoint);
    })
    .catch(() => {
      cachedScripts.delete(endpoint);
    });
};
