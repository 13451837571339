import {
  SET_TRANSLATE_LOADING_START,
  SET_TRANSLATE_LOADING_STOP,
  SET_INIT_LOADING_START,
  SET_INIT_LOADING_STOP,
  SET_LOGIN_LOADING_START,
  SET_LOGIN_LOADING_STOP,
  SET_CORE_REQUESTS_ERROR,
  RESET_CORE_REQUESTS_ERROR,
  INCREMENT_HEALTHCHECK_ACTION,
  SET_CORE_REQUESTS_ERROR_CODE,
  RESET_CORE_REQUESTS_ERROR_CODE,
  SET_FIRST_ROUTE_TRANSITION_FINISHED,
  SET_LOGOUT_LOADING_START,
  SET_LOGOUT_LOADING_STOP,
} from './actions';
import { ActionType, AppState } from './_types';

export const initialState: AppState = {
  initLoading: false,
  translateIsLoading: false,
  loginLoading: false,
  coreRequestsError: false,
  healthCheckCoreRequestsInterval: 10000,
  coreRequestsErrorCode: '',
  firstRouteTransitionFinished: false,
  logoutLoading: false,
};

export const reducerAppState = (
  state: AppState = initialState,
  { type, payload }: ActionType
): AppState => {
  switch (type) {
    case SET_INIT_LOADING_START:
      return {
        ...state,
        initLoading: true,
      };

    case SET_INIT_LOADING_STOP:
      return {
        ...state,
        initLoading: false,
      };

    case SET_TRANSLATE_LOADING_START:
      return {
        ...state,
        translateIsLoading: true,
      };

    case SET_LOGIN_LOADING_START:
      return {
        ...state,
        loginLoading: true,
      };

    case SET_LOGIN_LOADING_STOP:
      return {
        ...state,
        loginLoading: false,
      };

    case SET_TRANSLATE_LOADING_STOP:
      return {
        ...state,
        translateIsLoading: false,
      };

    case SET_CORE_REQUESTS_ERROR:
      return {
        ...state,
        coreRequestsError: true,
      };

    case SET_CORE_REQUESTS_ERROR_CODE:
      return {
        ...state,
        coreRequestsErrorCode: payload,
      };

    case RESET_CORE_REQUESTS_ERROR_CODE:
      return {
        ...state,
        coreRequestsErrorCode: '',
      };

    case RESET_CORE_REQUESTS_ERROR:
      return {
        ...state,
        coreRequestsError: false,
      };

    case INCREMENT_HEALTHCHECK_ACTION:
      return {
        ...state,
        healthCheckCoreRequestsInterval:
          state.healthCheckCoreRequestsInterval + 10000,
      };

    case SET_FIRST_ROUTE_TRANSITION_FINISHED:
      return {
        ...state,
        firstRouteTransitionFinished: true,
      };

    case SET_LOGOUT_LOADING_START:
      return {
        ...state,
        logoutLoading: true,
      };

    case SET_LOGOUT_LOADING_STOP:
      return {
        ...state,
        logoutLoading: false,
      };

    default:
      return state;
  }
};
