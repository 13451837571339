import { IMiddleware, IResponse } from '@portals/fetch-api/dist/types';
import { getLocale } from '@portals/i18next-utils';
import { OFFLINE_STATUS_CODE } from '@portals/fetch-api';
import { redirectToLoginPage } from '../../../../../_utils/redirect-to-login';
import { clearUserInfoCache } from '../../../../../_utils/api-cache/clear-user-info';
import { checkIsExternalApiRequest } from '../_utils/check-is-external-api-request';
import { loginApi } from './_utils/login-api';
import { getIsNotLogined } from './_utils/get-is-not-logined';

export const loginMiddleware: IMiddleware = async ({
  pureRequestParams,
  retryRequest,
  response,
}): Promise<IResponse> => {
  if (!response.error || response.code === OFFLINE_STATUS_CODE) {
    return response;
  }

  const locale = getLocale({ isFromCookie: true });

  try {
    const isExternalApiRequest = checkIsExternalApiRequest(
      pureRequestParams.endpoint
    );

    // works only with current domain
    if (isExternalApiRequest) {
      // pass to next login-separate-domain-middleware
      return response;
    }

    // check if not logined
    const isNotLogined = getIsNotLogined({
      responseBodyJson: JSON.stringify(pureRequestParams.responseBody),
      code: pureRequestParams.code,
    });

    if (!isNotLogined) {
      return response;
    }

    await loginApi();

    // retry request with login
    const responseWithLogin = await retryRequest({
      middlewaresAreDisabled: true,
    });

    // return response
    return responseWithLogin;
  } catch (error) {
    // if SSo not valid - there will be an exception - to redirect to login page
    console.error('loginMiddleware error', error);

    clearUserInfoCache();

    redirectToLoginPage(locale);

    return response;
  }
};
