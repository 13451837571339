import { createSelector } from 'reselect';
import { reducerPlatformModulesName } from './constants';
import {
  PlatformModulesState,
  StatePartPlatformModules,
  ModulesListType,
} from './types';
import { initialState } from './reducer';

const productsManagerStorageSelector = (store: StatePartPlatformModules) =>
  store[reducerPlatformModulesName] || initialState;

export const getPlatformModulesData = createSelector(
  productsManagerStorageSelector,
  ({ modules }: PlatformModulesState): ModulesListType => modules
);

export const getPlatformModulesIsLoading = createSelector(
  productsManagerStorageSelector,
  ({ loading }: PlatformModulesState): boolean => loading
);
