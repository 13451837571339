import { setPermissionsAction } from '@portals/databus-service-user';
import { setUserPermissionsAction } from '@portals/service-user';
import { Dispatch } from 'redux';
import { getFormattedPermissions } from '../../get-formatted-permissions';

type ParamsType = {
  dispatch: Dispatch;
  permissions: any;
};

export const formatPermissionsAndDispatch = ({
  dispatch,
  permissions,
}: ParamsType) => {
  try {
    const { userPermissions } = getFormattedPermissions(permissions);

    dispatch(setUserPermissionsAction({ permissions: userPermissions }));
    setPermissionsAction(userPermissions);
  } catch (error) {
    dispatch(
      setUserPermissionsAction({
        permissions: { [window.ENVS.MAIN_ABAC_SCOPE]: {} },
      })
    );
  }
};
